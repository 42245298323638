/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import loginServices from "../../services/login";
import { useToasts } from 'react-toast-notifications';
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import HomePageCategoryMenu from './categoryMenus/homePageCategoryMenu'
import categoryService from "../../services/productCategories";
import PageLoader from '../../components/pageLoader'
import { Auth } from '../../Contexts/AuthContext';
import { isMobile } from "react-device-detect";
// import languages from '../../assets/languages.json'
import { langContext } from '../../Contexts/langContext';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
require('dotenv').config()





const Header = (props) => {
  const { globalFilter, setGlobalFilter, setShowLoginModal, walletBalance, newMessageCount, userProfileData , menuCategories, setMenuCategories } = useContext(Auth);
  const { lang } = useContext(langContext);
  const checkAuth = props.checkAuth;
  const isHomePage = props.data;
  const [showMobileSerach, setShowMobileSerach] = useState(false);
  const [isLoggedIn, setLogin] = useState(false);

  const [userStats, setUserStats] = useState();
  // const [searchLang, setSearchLang] = useState('');
  // const [languageList, setLanguageList] = useState([]);
  const [loaded, setLoaded] = useState(true);
  var isLogin = window.localStorage.getItem('isLoggedIn') ? window.localStorage.getItem('isLoggedIn') : false;
  var user = window.localStorage.getItem('loggedUser') ? window.localStorage.getItem('loggedUser') : false
  var checkLogin = isLoggedIn ? isLoggedIn : isLogin;
  const { t } = useTranslation();


  const { addToast } = useToasts();
  const location = useLocation();
  const navigate = useNavigate();
  const checkAuthRoute = () => {
    // setLanguageList(languages?.data)
    if (location.pathname !== '/') {
      if (!isLogin && checkAuth) {
        addToast(`${t("Please Login befor accessing this page")}...`, { appearance: 'warning' });
        navigate('/');

      }
    }
  }


  // const getLangByCode = (code) => {
  //   const lang = languages?.data?.find(lang => ((lang?.code).toLocaleUpperCase()).includes(code.toLocaleUpperCase()));
  //   if (lang !== undefined) {
  //     return lang?.name;
  //   } else {
  //     return code;
  //   }
  // }


  useEffect(checkAuthRoute, []);
  useEffect(() => {
    categoryService.categoryMenus(lang)
      .then(res => {
        if (res.success) {
          setMenuCategories(res.data);
          setUserStats(res.stats);
        }

      })
      .catch(err => {
        // console.log('eooer');
      })
  }, [])

  // handle logout 
  const handleLogOut = () => {
    const newToken = JSON.parse(user).access_token;

    loginServices.setToken(newToken)
    setLoaded(false);
    loginServices
      .logOut()
      .then(response => {
        setLogin(false);
        window.localStorage.removeItem('isLoggedIn');
        window.localStorage.removeItem('loggedUser');
        window.localStorage.removeItem('total_balance');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('issubscribed');
        window.localStorage.removeItem('default_currency');
        window.localStorage.removeItem('currency_symbol');
        addToast(t("User Logged Out Successfully"), { appearance: 'success' })
        setLoaded(true);
        window.location.reload('/')
        // return <Redirect to="/" push="true" />
      })
      .catch(error => {
        if (error.response) {
          addToast(t("User Logged Out Successfully"), { appearance: 'success' })
          setLogin(false);
          window.localStorage.removeItem('isLoggedIn');
          window.localStorage.removeItem('loggedUser');
          window.localStorage.removeItem('total_balance');
          setLoaded(true);
          return <Navigate to="/" push="true" />
        }
      })
  }

  // user info dropdown 
  const UserInfobar = (props) => {

    var data = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false

    var userProfileData = props.userProfileData;

    var name = '';
    var image = ''
    if (userProfileData) {
      name = `${userProfileData.username}`;
      image = userProfileData.image;
    } else {
      // eslint-disable-next-line no-unused-vars
      name = `${data.username} `;
      image = data.picture;
    }
    const CurrentUserImage = (props) => {
      if (data) {
        return (
          <div>
            <span className="">
              <span className={`account-image-${data?.issubscribed ? 'verified' : 'unverified'}`}>
                <Image src={image ? image : '/images/user.png'} roundedCircle alt={''} />
              </span>
            </span>

            <i className="fa fa-caret-down ml-1" aria-hidden="true"></i>
          </div>
        )
      }
      return (
        <>
        </>
      )
    }
    const logout = () => {
      props.logout();
    }

    return (
      <div className="dropdown user-information">
        <div className="user-account-name">

          <div className="dropbtn"><div className="account-name user-account-profile-section"><CurrentUserImage data={data} /></div></div>
        </div>
        <div className="triangle-left"></div>
        <div className="dropdown-content profile-dropdown-content">
          <ul>
            <li><div className="icon"><Image src="/images/profile-icon/profile.svg" width="16px" className="img-fluid" alt="" /></div> <Link className="link" to="/profile">{t("My Profile")}</Link></li>
            <li><div className="icon"><Image src="/images/profile-icon/my_list.svg" width="16px" className="img-fluid" alt="" /> </div> <Link className="link" to="/my-listing">{t("My Listings")}</Link></li>
            {isMobile ? (
              <>
                <li><div className="icon"><Image src="/images/profile-icon/favorite.svg" width="16px" className="img-fluid" alt="" /></div> <Link className="link" to="/my-favourite">{t("My Favorites")}</Link></li>
                <li><div className="icon"><Image src="/images/profile-icon/sell_now.svg" width="16px" className="img-fluid" alt="" /> </div> <Link className="link" to="/product/add">{t("Sell Now")}</Link></li>
                <li><div className="icon"><Image src="/images/profile-icon/chat.svg" width="16px" className="img-fluid" alt="" /> </div> <Link className="link" to="/messages">{t("Chat")}</Link></li>
              </>
            ) : (
              <></>
            )
            }
            <li><div className="icon"><Image src="/images/profile-icon/setting.svg" width="16px" className="img-fluid" alt="" /> </div> <Link className="link" to="/setting">{t("My Settings")}</Link></li>
            <li><div className="icon"><Image src="/images/profile-icon/help.svg" width="16px" className="img-fluid" alt="" /> </div> <Link className="link" to="/support">{t("Help & Support")}</Link></li>
            <li onClick={logout} ><div className="icon" ><Image src="/images/profile-icon/logout.svg" width="16px" className="img-fluid" alt="" /></div> <Link className="link" to="/">{t("Logout")}</Link></li>
          </ul>
        </div>
      </div>
    )
  }

  // silder section of header
  const SliderSection = (props) => {
    const { setShowLoginModal } = useContext(Auth);
    var loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false
    if (props.data) {
      return (
        <>
          {loginUser ? (
            <div className="slider-section">
              <div>
                <Row className="no-gutters">

                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="carousel-slider">
                      <Carousel>
                        <Carousel.Item interval={5000}>
                          <Image className="d-block w-100" src="/images/banner2.webp" alt="First slide" />
                          <Carousel.Caption>
                            <div className="home-slider1">
                              <h3>{t("The international marketplace for")} <br /> {t("military traders & collectors")}</h3>
                              <p>{t("Trade your items and grow your collection from the largest worldwide selection")}<br /> {t("of militaria, vehicles, parts & weapons")}</p>
                              <div className="slider-button"><Link to="/product/add">{t("Sell now")}</Link> </div>
                            </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                          <Image className="d-block w-100" src="/images/slider2.webp" alt="First slide" />
                          <Carousel.Caption>
                            {loginUser?.issubscribed ? (
                              <div className="home-slider2 login-home-slider2">
                                <h3>{t("Invite friends and")} <br /> {t("get rewarded")}!</h3>
                                <p>{t("Invite your friends to Miltrade and receive free credits")} <br />{t("when they sign up and list their first item")}</p>
                                <div className="slider-button"><Link to="/setting?page=Invite a friend">{t("Invite friends")}</Link> </div>
                              </div>
                            ) : (

                              <div className="home-slider2 login-home-slider2">
                                <h3>{t("Upgrade your account and")} <br /> {t("get unlimited benefits")}</h3>
                                <p>{t("Become a Verified Dealer, receive a verified badge, and enjoy")} <br />{t("Miltrade without limits")}</p>
                                <div className="slider-button"><Link to="/become-a-verified-dealer">{t("Become a verified dealer")}</Link> </div>
                              </div>
                            )}

                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ) : (

            <div className="slider-section">
              <div>
                <Row className="no-gutters">

                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="carousel-slider">
                      <Carousel>
                        <Carousel.Item interval={5000}>
                          <Image className="d-block w-100" src="/images/banner2.webp" alt="First slide" />
                          <Carousel.Caption>
                            <h3>{t("The international marketplace for")} <br /> {t("military traders & collectors")}</h3>
                            <p>{t("Trade your items and grow your collection from the largest worldwide selection")}<br /> {t("of militaria, vehicles, parts & weapons")}</p>
                            <div className="slider-button"><Link onClick={setShowLoginModal} >{t("Sell now")}</Link> </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                          <Image className="d-block w-100" src="/images/slider2.webp" alt="First slide" />
                          <Carousel.Caption>
                            <div className="home-slider2">
                              <h3>{t("Sign up today and start selling")} <br />{t("and buying immediately")}!</h3>
                              <p>{t("Create a free Miltrade account to use all our features and to list your")} <br /> {t("own military items for sale")}</p>
                              <div className="slider-button"><Link onClick={setShowLoginModal}>{t("Register now")}</Link> </div>
                            </div>
                          </Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </>

      )
    }
    return (
      <>
      </>
    )
  }

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  }
  const getSelectedCategoryName = (category_slug) => {
    const category = menuCategories?.find(cat => String(cat.slug) === String(category_slug));
    if (category !== undefined) {
      return category.name
    } else {
      return capitalize(category_slug);
    }
  }

  // handle serach 
  const handleGlobalSerach = (e) => {
    e.preventDefault();
    localStorage.setItem('searchText',searchText?.current?.value);
    const category = globalFilter.filter_category ? globalFilter.filter_category : `${t('All categories')}`;
    navigate(`/search/${category}`)
  }

  // handle serach onChange 
  const globalFilterHandle = (e) => {
    e.preventDefault()
    localStorage.setItem('filterCategory', e.currentTarget.dataset.value);

    setGlobalFilter({
      ...globalFilter,
      filter_category: e.currentTarget.dataset.value,
      filter_name: e.currentTarget.dataset.name,
      serachClicked: false,
    });
  }

  // handle serach onChange 
  const globalFilterHandleMobile = (e) => {
    const { name, value: newValue, type } = e.target;
    const value = type === 'number' ? +newValue : newValue;
    localStorage.setItem('filterCategory', value);
    setGlobalFilter({
      ...globalFilter,
      [name]: value,
      serachClicked: false,
    });

  }
  const searchText = useRef(null);



  const mobleSerachStyle = {
    display: showMobileSerach ? 'block' : 'none'
  }


  return (
    <>
      {/* Start of header section */}
      <header>
        <div className="mobi-overlay">
          <span className="close"></span>
        </div>
        <PageLoader isLoaded={loaded} />
        <div className="">
          <div className="dest-header">
            <div className="sticky">
              <div className="sticky-header">
                <div className="header-top">
                  <Container fluid className="top-header-wrapper d-none d-lg-block d-md-block">
                    <Row className="align-items-center">
                      <Col sm={6} md={4} lg={4}>
                        <div>
                          <i className="fa fa-user"></i> {userStats?.users} {t('active users worldwide')}
                        </div>
                      </Col>
                      <Col sm={6} md={4} lg={4}>
                        <div>
                          <i className="icon-website_arrow"></i> {userStats?.visitors} {t('daily visitors')}
                        </div>
                      </Col>

                      <Col sm={6} md={4} lg={4}>
                        <div className='top-header-text'>
                          <i className="icon-percent"></i>  {t('Start selling today, its free!')}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className='middle-header-wrapp'>
                    <Container fluid>
                      <Row className='align-items-center'>
                        <Col xs={12} sm={4} md={3} lg={2}>
                          <div className="logo">
                            <Link to="/"><Image src="/images/logo/miltrade_logo.svg" alt="flag" width='160px' className="img-fluid" /></Link>
                          </div>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={5}>
                          <div className="product-extra-search">
                            <Form onSubmit={handleGlobalSerach} className="products-search">
                              <div className="input-group">
                                <Dropdown>
                                  <div className="subCategoryWrapper">
                                    <Dropdown.Toggle className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
                                      <div className={`dropbtn `}>
                                        {globalFilter?.filter_category ? getSelectedCategoryName(globalFilter?.filter_category) : `${t("All categories")}`}
                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={globalFilterHandle}
                                        data-value={`${t("All categories")}`}
                                        data-name={`${t("All categories")}`}
                                      >  {`${t("All categories")}`}
                                      </Dropdown.Item>
                                      {menuCategories?.map((cat, i) =>
                                        <Dropdown.Item onClick={globalFilterHandle}
                                          data-value={cat?.slug}
                                          data-name={cat?.name}
                                          key={i} >  {cat?.name}
                                        </Dropdown.Item>
                                      )}
                                    </Dropdown.Menu>
                                  </div>
                                </Dropdown>
                                <Form.Control
                                  className={`form-control`}
                                  type="text"
                                  name="filter_text"
                                  ref={searchText}
                                  placeholder={`Search in ${globalFilter?.filter_category ? globalFilter?.filter_category : `${t("All categories")}`}`}
                                />
                                <div className="input-group-append">
                                  <button className="btn btn-outline-secondary" type="submit" onClick={handleGlobalSerach} ><i className="fa fa-search"></i></button>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5}>
                          <div className='language-and-profile-wrapper'>
                            {!isMobile ? (
                              <></>
                              // <div className='language-translator'>
                              //   <>
                              //     <Image src="/images/globe.svg" alt="flag" width='16px' className="img-fluid" /> <div id=""></div>
                              //     <Dropdown>
                              //       <div className="subCategoryWrapper">
                              //         <Dropdown.Toggle className="" variant="success" id="dropdown-basic">
                              //           <div className={`dropbtn `}>
                              //             {lang ? getLangByCode(lang) : "Select language"}
                              //           </div>
                              //         </Dropdown.Toggle>
                              //         <Dropdown.Menu>
                              //           {languageList?.map((lang, i) =>
                              //             <Dropdown.Item onClick={(e) => { changeLang(e.currentTarget.dataset.value) }}
                              //               data-value={lang?.code}
                              //               data-name={lang?.nativeName}
                              //               key={i} >  {lang?.name}
                              //             </Dropdown.Item>
                              //           )}
                              //         </Dropdown.Menu>
                              //       </div>
                              //     </Dropdown>

                              //   </>
                              // </div>
                            ) : (
                              <></>
                            )
                            }

                            {(() => {
                              if (!checkLogin) {
                                return (
                                  <div className="login-section">
                                    <ul>
                                      <li><a onClick={() => { setShowLoginModal(true) }} className="sign-in cp" ><i className="icon-account"></i> {t("Register")} | {t("Sign in")}</a></li>
                                      <li><Link className="place-listing link" onClick={() => setShowLoginModal(true)}><i className="icon-place_listing"></i> {t("Sell now")}</Link></li>
                                      <li className="faq"><Link className="" to="/support"> <Image src="/images/faq.svg" width="22px" className="img-fluid" alt=""></Image></Link></li>
                                    </ul>
                                  </div>
                                )
                              } else {
                                return (
                                  <div className="logined">
                                    <ul className="">
                                      <li>
                                        <div className="message-notification">
                                          <Link className="" to="/messages"><Image src="/images/chat.svg" width="17px" className="img-fluid" alt="" />
                                            {newMessageCount > 0 && (<span className="message-count">{newMessageCount}</span>)}
                                          </Link>
                                        </div>
                                      </li>
                                      <li>
                                        <Link className="" to="/my-favourite"><i className="fa fa-heart-o" aria-hidden="true"></i></Link>
                                      </li>
                                      <li className="login-user-detail">
                                        <UserInfobar userProfileData={userProfileData} walletBalance={walletBalance} logout={handleLogOut} />
                                      </li>
                                      <li><Link className="place-listing link" to="/product/add"><i className="icon-place_listing"></i> {t("Sell now")}</Link></li>
                                    </ul>
                                  </div>
                                )
                              }
                            })()}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
                <div className="navication-section">
                  <Row className="no-gutters">
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <Navbar collapseOnSelect expand="lg" variant="dark">
                        <div className="top-header-wrapper d-block d-lg-none">
                          <div className="top-header-inner">
                            <div className="top-header-box1">
                              <i className="fa fa-user"></i> {userStats?.users} {t("active users worldwide")}
                            </div>
                            <div className="top-header-box1 top-header-box2">
                              <i className="icon-website_arrow"></i> {userStats?.visitors} {t("daily visitors")}
                            </div>
                          </div>
                        </div>
                        <div className='mobile-header-sections'>
                          <div className="home-logo">
                            <Navbar.Brand href="/">
                              <Image src="/images/logo/miltrade_logo.svg" alt="flag" width='130px;' className="img-fluid" />
                            </Navbar.Brand>
                          </div>
                          <div className='mobile-header-right-sections'>
                            {isMobile ? (
                              <></>
                              // <div className='language-translator'>
                              //   <>
                              //     <Image src="/images/globe.svg" alt="flag" width='16px' className="img-fluid" /> <div id=""></div>
                              //     <Dropdown>
                              //       <div className="subCategoryWrapper">
                              //         <Dropdown.Toggle className="" variant="success" id="dropdown-basic">
                              //           <div className={`dropbtn `}>
                              //             {lang ? getLangByCode(lang) : "Select language"}
                              //           </div>
                              //         </Dropdown.Toggle>
                              //         <Dropdown.Menu>
                              //           {languageList?.map((lang, i) =>
                              //             <Dropdown.Item onClick={(e) => { changeLang(e.currentTarget.dataset.value) }}
                              //               data-value={lang?.code}
                              //               data-name={lang?.nativeName}
                              //               key={i} >  {lang?.name}
                              //             </Dropdown.Item>
                              //           )}
                              //         </Dropdown.Menu>
                              //       </div>
                              //     </Dropdown>

                              //   </>
                              // </div>
                            ) : (
                              <></>
                            )
                            }
                            {isMobile ? (
                              <div className='mobile-user-profile'>
                                <UserInfobar userProfileData={userProfileData} walletBalance={walletBalance} logout={handleLogOut} />
                              </div>
                            ) : (
                              <></>
                            )
                            }
                            <div className='mobile-toggle'>
                              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            </div>
                          </div>
                        </div>

                        <span onClick={() => setShowMobileSerach(!showMobileSerach)} className="search-icon cp" ><i className="fa fa-search" aria-hidden="true"></i></span>
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
                          <HomePageCategoryMenu dealersMenuText={t('Dealers & Services')} menusCat={menuCategories} />

                          {(() => {
                            if (!checkLogin) {
                              return (
                                <div className="login-section2">
                                  <ul>
                                    <li> <a className="sign-in" onClick={() => setShowLoginModal(true)} href="#"><i className="icon-account"></i>  {t("Register")} | {t("Sign in")}</a></li>
                                    <li><Link className="place-listing link" onClick={() => setShowLoginModal(true)} ><i className="icon-place_listing"></i> {t("Sell now")}</Link>
                                      <Link className="" to="/support"> <Image src="/images/faq.svg" width="22px" className="img-fluid" alt=""></Image></Link></li>

                                  </ul>
                                </div>
                              )
                            } else {
                              return (
                                <div className="logined">
                                  <ul className="logined2">
                                    <div className="user-info">
                                      <li>
                                        <div className="message-notification">
                                          <Link className="" to="/my-favourite"> <span className="mobile-display"> <i className="fa fa-heart-o" aria-hidden="true"></i>  {t("Favorites")} </span> <span className="mobile-hide"><i className="fa fa-heart-o" aria-hidden="true"></i> </span> </Link>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="message-notification">
                                          <Link className="" to="/messages"> <span className="mobile-display"> <i className="icon-email_notifications"></i> {t("Messages")}</span> <span className="mobile-hide"><i className="icon-email_notifications"></i></span>
                                            {newMessageCount > 0 && (<span className="message-count">{newMessageCount}</span>)}
                                          </Link>
                                        </div>
                                      </li>
                                    </div>
                                    <li>
                                      <UserInfobar userProfileData={userProfileData} walletBalance={walletBalance} logout={handleLogOut} />

                                    </li>
                                    <li>
                                      <Link className="place-listing link" to="/product/add"><i className="icon-place_listing"></i> {t("Sell now")}</Link>
                                    </li>
                                  </ul>
                                </div>
                              )
                            }
                          })()}

                        </Navbar.Collapse>
                      </Navbar>
                    </Col>
                  </Row>
                  <div className="cs" style={mobleSerachStyle}>
                    <span onClick={() => setShowMobileSerach(!showMobileSerach)} className="cancel-icon cp" ><i className="icon-cancel"></i></span>
                    <form>
                      <div className="form-group">
                        <select className="form-control category-section" name='filter_category' defaultValue={globalFilter.filter_category} onChange={globalFilterHandleMobile} id="exampleFormControlSelect2">
                          {/* <option value={t("All categories")}>{t("All categories")}</option> */}
                          {menuCategories?.map((cat, i) => (
                            <option key={i} value={cat.slug}>{cat.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <input type="text" className="form-control" name='filter_text' value={globalFilter.filter_text} onChange={globalFilterHandleMobile} placeholder={`Search in ${globalFilter?.filter_category ? globalFilter?.filter_category : `${t("All categories")}`}`} />
                      </div>
                      <div className="update-profile-submit mt-0">
                        <button className="btn btn-primary" onClick={handleGlobalSerach} type="button">{t("Search")}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SliderSection
          userProfileData={userProfileData}
          data={isHomePage}
        />

      </header>
    </>
  )
}


export default Header;