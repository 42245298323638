/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Auth } from '../../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';


const HomePageCategoryMenu = ({ menusCat, dealersMenuText }) => {
    const navigate = useNavigate();
    const { globalFilter, setGlobalFilter } = useContext(Auth);
    // redirection handle on select 
    const handleRedirect = (cat) => {
        localStorage.setItem('filterCategory', cat.slug);
        setGlobalFilter((prv) => ({
            ...prv,
            filter_category: cat.slug,
            filter_name: cat.name
        }));
        if (cat.slug !== 'dealers')
            navigate(`/category/${cat.slug}`);
        else
            navigate(`/dealers`);
    }
    const { t } = useTranslation();


    return (
        <>
            {isMobile ? (
                <Nav className="justify-content-center navbar-expand-lg navbar-dark" activeKey="/home">
                    <div className="navbar-logo"> <a href="/"> <Image src="/images/logo/miltrade_logo.svg" alt="flag" width='158px;' className="img-fluid" /></a></div>

                    {menusCat?.map((cat, i) => (
                        <span key={i} className="mobile-nav">
                            <Nav.Item  >
                                <span onClick={() => handleRedirect(cat)} className={`${String(globalFilter.filter_category) === String(cat.slug) ? 'active' : ''}`} > <a className={`nav-link ${String(globalFilter.filter_category) === String(cat.slug) ? 'active' : ''}`} ><Image src={cat.icon} alt="" className="img-fluid" /> {cat.name} </a> </span>
                            </Nav.Item>
                        </span>
                    ))}
                    <Nav.Item>
                        <span onClick={() => handleRedirect({ name: 'Dealers', slug: 'dealers' })} className={`mobile-nav home-dealers ${String(globalFilter.filter_category) === "dealers" ? 'active' : ''}`}> <Link className={`nav-link ${String(globalFilter.filter_category) === 'dealers' ? 'active' : ''}`} ><i className="icon-dealers"></i> {t("Dealers & Services")}</Link></span>
                    </Nav.Item>
                </Nav>

            ) : (
                <Nav className="justify-content-center navbar-expand-lg navbar-dark" activeKey="/home">
                    <div className="navbar-logo"> <a href="/"> <Image src="/images/logo/miltrade_logo.svg" alt="flag" width='158px;' className="img-fluid" /></a></div>

                    {menusCat?.map((cat, i) => (
                        <span key={i} className="mobile-nav">
                            <Nav.Item  >
                                <span onClick={() => handleRedirect(cat)} className={`${String(globalFilter.filter_category) === String(cat.slug) ? 'active' : ''}`} > <Link className={`nav-link ${String(globalFilter.filter_category) === String(cat.slug) ? 'active' : ''}`} ><Image src={cat.icon} alt="" className="img-fluid" /> {cat.name} </Link> </span>
                            </Nav.Item>
                        </span>
                    ))}
                    <Nav.Item>
                        <span onClick={() => handleRedirect({ name: 'Dealers', slug: 'dealers' })} className={`mobile-nav home-dealers ${String(globalFilter.filter_category) === "dealers" ? 'active' : ''}`}> <Link className={`nav-link ${String(globalFilter.filter_category) === 'dealers' ? 'active' : ''}`} ><i className="icon-dealers"></i> {dealersMenuText}</Link></span>
                    </Nav.Item>
                </Nav>

            )}
        </>
    )
}

export default HomePageCategoryMenu