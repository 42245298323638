/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Header from "../../include/header";
import Footer from "../../include/footer";
import productCategories from "../../../services/productCategories";
import postsService from "../../../services/posts";
import walletServices from "../../../services/wallet";
import { useToasts } from 'react-toast-notifications'
import { useLocation, useNavigate, Link } from "react-router-dom";
import 'bootstrap-daterangepicker/daterangepicker.css';
import ProductCategories from './productCategories'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import MyLoader from '../../myloader';
import PageLoader from '../../pageLoader'
import Cropper from 'react-easy-crop'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import getCroppedImg from './croppedImage'
import RangeSlider from 'react-bootstrap-range-slider';
import SeoTags from '../../seo/seoTags'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import moment from 'moment';
import { loadStripe } from '@stripe/stripe-js';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Swal from 'sweetalert2'
import axios from "axios";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { nanoid } from 'nanoid'
import Compressor from 'compressorjs';
import { Auth } from '../../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { langContext, useLangContext } from '../../../Contexts/langContext';
require('dotenv').config()


// const stripePromise = loadStripe('pk_live_51IKpYGLFZZQVMIHLbSAc2QFG2nYEZg7lwj0ULBlFKEknkLmPwvdOtZ3KJpoIheJ5pohZRBPKsijVVO6tjyQRMxtq00Qf4kESAy');
const ProductCreate = () => {
	const { lang } = useLangContext(langContext);
	const { t } = useTranslation();
	const { setWalletBalance } = useContext(Auth);
	const prefix = process.env.REACT_APP_BASE_URL;
	const baseUrl = prefix + '/posts';
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

	const { addToast } = useToasts();
	const location = useLocation();
	const navigate = useNavigate();
	const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
	const token = loginUser ? loginUser.access_token : false;
	const default_currency = window.localStorage.getItem('default_currency');
	const query = new URLSearchParams(location.search);
	const postId = query.get('post_id') ? query.get('post_id') : false;
	const paymentStatus = query.get('status') ? query.get('status') : false;

	const [postData, setPostData] = useState();
	const [oldImages, setOldImages] = useState([]);
	const [postImages, setPostImages] = useState([]);
	const [optionData, setOptionData] = useState([]);
	const [allProductCategories, setProductCategories] = useState([]);
	const [selectedMainCategory, setSelectedMainCategory] = useState();
	const [minYear, setMinYear] = useState('');
	const [maxYear, setMaxYear] = useState('');
	const [subCategoris, setSubCategoris] = useState([]);
	const [show, setShow] = useState(false);
	const [maxFlieUploadError, setMaxFlieUploadError] = useState(false);
	const [selectedOptionData, setSelectedOptionData] = useState([]);
	const [phoneHidden, setPhoneHidden] = useState(true);
	const [selectedPhoneOption, setSelectedPhoneOption] = useState();
	const [createProductForm, setCreateProductForm] = useState({
		title: '',
		description: '',
		phone: loginUser?.phone,
		price: '',

	});
	const [isImageUploading, setIsImageUploading] = useState(false);
	const [rate, setRate] = useState(0);
	const [titleTxtcounter, setTitleTxtcounter] = useState(0);
	const [validationError, setValidationError] = useState();
	const [loaded, setLoaded] = useState(false);
	const [formImages, setFormImages] = useState([]);
	const [currentBalance, setCurrentBalance] = useState(0);
	const [currencyList, setCurrencyList] = useState([]);
	const [openToOffers, setOpenToOffers] = useState(false);
	const [postTempData, setPostTempData] = useState([]);
	const [filledSubcategory, setFilledSubcategory] = useState(false);
	const [filterString, setFilterString] = useState('');
	const swalWithBootstrapButtons = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			// cancelButton: 'mr-2 btn btn-danger'
		},
		buttonsStyling: false
	})



	// Cropper
	const [upImg, setUpImg] = useState();
	const [cropper, setCropper] = useState(false);
	const [rotation, setRotation] = useState(0)
	const [croppedImage, setCroppedImage] = useState(null)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
	const [zoom, setZoom] = useState(1)
	const [crop, setCrop] = useState({
		x: 0, y: 0,
		cropSize: { width: 450, height: 251 },
		aspect: 4 / 3,
	})
	const [subscribedUser, setSubscribedUser] = useState(false);
	var isHomePage = false;

	const [monthPiker, setMonthPicker] = useState({
		rangeValue: { from: { year: new Date().getFullYear(), month: new Date().getMonth() }, to: { year: new Date().getFullYear(), month: new Date().getMonth() } },
	})
	const [completed, setCompleted] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [phoneCodes, setPhoneCodes] = useState([]);
	const [filteredPhoneCodes, setFilteredPhoneCodes] = useState([]);
	const [loaderdata, setLoaderdata] = useState({
		title: "",
		message: "",
	});
	const [minDate, setMinDate] = useState('');
	const [maxDate, setMaxDate] = useState('');

	let env = process.env.REACT_APP_PAYPAL_ENV;
	let currency = default_currency;
	const buttonStyles = {
		size: 'medium',
		color: 'black',
		tagline: false,
		shape: 'rect',
		label: 'pay'
	}

	const client = {
		sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID,
		production: process.env.REACT_APP_PAYPAL_CLIENT_ID

	}

	// payment handling in case of low credit in wallet
	const [paymentModal, setPaymentModal] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(false);
	const [totalAmount, setTotalAmount] = useState('');
	const [credit, setCredit] = useState('');
	const [postID, setPostID] = useState(false);



	const arrayMove = (items, oldIndex, newIndex) => {
		items = items.slice();
		items.splice(newIndex < 0 ? items.length + newIndex : newIndex, 0, items.splice(oldIndex, 1)[0]);
		return items;
	}

	// get currencies from server 
	const allCurrencies = async () => {
		productCategories.getCurrency()
			.then(response => {
				if (response.success) {
					setCurrencyList(response.data);
				}
			})
			.catch(error => {
				if (error.response) {
					setLoaded(true);
					setTimeout(function () {
						addToast(t('Some Error Occured'), { appearance: 'error' });
					}, 1000);
				}
			})
	}

	useEffect(() => {
		async function fetchData() {
			if (!loginUser) {
				return false;
			}
			productCategories.setToken(token)
			await allCurrencies()
			if (token) {
				if (postId) {
					if (paymentStatus === "failed") {
						swalWithBootstrapButtons.fire(
							t('Payment failed'),
							t('please try again'),
							'error'
						)

						postsService.setToken(token)
						setLoaded(false);
						await postsService
							.editPost(postId)
							.then(response => {
								if (response.success) {
									setPostData(response.data);
									setPhoneCodes(response.data.phone_codes)
									setFilteredPhoneCodes(response.data.phone_codes)
									setRate(response.data.rate)
									setSelectedMainCategory(response.data.catid)
									setProductCategories(response.data.allcategories);
									const category = response.data.allcategories.find(c => parseInt(c.id) === parseInt(response.data.catid))
									setSubCategoris(category.sub_category_fields)
									var details = [];
									// eslint-disable-next-line array-callback-return
									response.data.details.map(op => {
										details[op.id] = op.optionvalueid;
									})
									var options = Object.assign({}, details);
									setSelectedOptionData(options);
									setOptionData(options);
									setCreateProductForm({
										title: response.data.title,
										description: response.data.description,
										price: response.data.price,
										phone: response.data.phone,


									});
									setOpenToOffers(response.data.open_to_offer ? response.data.open_to_offer : false);
									setTitleTxtcounter(response.data.title.length);
									setOldImages(response.data.picture)

									if (response.data.picture.length > 0) {
										let imageArray = [];
										for (let index = 0; index < response.data.picture.length; index++) {
											const image = response.data.picture[index];

											let imageData = {
												id: image.id,
												imagePreview: image.thumbnail,
												image: image,
												uploaded: 100,
												completed: true,
												failed: false,
												type: 'OLD',
												uniqueId: nanoid(10),
											}
											imageArray.push(imageData);
										}
										setPostImages(imageArray);
									}
									setPhoneHidden(!response.data.phone_hidden)
									if (response.data.phone_code) {
										let tempData = response.data.phone_codes.find(p => String(p.phone_code) === String(response.data.phone_code));
										setSelectedPhoneOption({
											phoneCode: tempData?.phone_code,
											flag: tempData?.flag
										})
									} else {
										let tempData = response.data.phone_codes[0];
										setSelectedPhoneOption({
											phoneCode: tempData?.phone_code,
											flag: tempData?.flag
										})
									}
									setLoaded(true);

								}
							})
							.catch(error => {
								if (error.response) {
									swalWithBootstrapButtons.fire(
										t('Payment failed'),
										error.response.data.message,
										'error'
									)
									navigate('/');
								}
							})
					}
					if (paymentStatus === "succeeded") {
						postsService.setToken(token)
						setLoaded(false);
						await postsService
							.activatePost(postId)
							.then(response => {
								setLoaded(true);
								if (response.success) {
									setWalletBalance(response.data.wallet_balance);
									window.localStorage.setItem('total_balance', response.data.wallet_balance);
									setShow(false);
									navigate({
										pathname: '/listed',
										postData: response.data
									})

								}
							})
							.catch(error => {
								setLoaded(true);
								if (error.response) {
									swalWithBootstrapButtons.fire(
										t('Error'),
										error.response.data.message,
										'error'
									)
									navigate('/product/add');
									window.open(window.location.href, "_self")
								}
							})
					}
				}
				else {
					productCategories
						.getAllCatgories(lang)
						.then(response => {
							if (response.success) {
								setTimeout(function () {
									setProductCategories(response.categories)
									setPhoneCodes(response.phone_codes)
									setFilteredPhoneCodes(response.phone_codes)
									setCurrentBalance(response.wallet_balance)
									setRate(response.categories[0].rate)
									setSelectedMainCategory(response.categories[0].id)
									if (response.categories[0].sub_category_fields) {
										setSubCategoris(response.categories[0].sub_category_fields)
									}
									setSubscribedUser(response.issubscribed)
									if (loginUser?.country_code) {
										let tempData = response.phone_codes.find(p => String(p.country_code) === String(loginUser?.country_code));
										setSelectedPhoneOption({
											phoneCode: tempData?.phone_code,
											flag: tempData?.flag
										})
									} else {
										let tempData = response.phone_codes[0];
										setSelectedPhoneOption({
											phoneCode: tempData?.phone_code,
											flag: tempData?.flag
										})
									}
									setLoaded(true);
								}, 1000);

							}
						})
						.catch(error => {
							if (error.response) {
								setLoaded(true);
								setTimeout(function () {
									addToast(t('Some Error Occured'), { appearance: 'error' });
								}, 1000);
							}
						})
				}
			}
		}
		fetchData();
	}, [token])

	// on handle submit of form 
	const handleSubmit = (e) => {

		e.preventDefault();

		if (isImageUploading) {
			addToast(t('Please wait images are still being uploaded'), { appearance: 'error' });
			return
		}
		// setLoaded(false);
		const formData = new FormData();
		postsService.setToken(token);
		formData.append('email', loginUser.email);
		formData.append('contact_name', `${loginUser.first_name} ${loginUser.last_name}`);
		formData.append('city_id', loginUser.city_id);
		formData.append('is_permanent', 0);
		formData.append('category_id', selectedMainCategory);
		formData.append('phone_hidden', phoneHidden ? 0 : 1);
		formData.append('open_to_offer', openToOffers ? 1 : 0);
		formData.append('issubscribed', subscribedUser);
		// formData.append('rate',rate);
		formData.append('rate', 0);

		if (postImages) {
			for (let i = 0; i < postImages.length; i++) {
				if (postImages[i]['id'] > 0) {
					formData.append(`pictures[${i}]`, postImages[i]['id'])
				}
			}
		}

		if (postId) {
			formData.append(`post_id`, postId)
		}

		if (selectedOptionData) {
			Object.keys(selectedOptionData).forEach(function (key) {
				formData.append(`cf[${key}]`, selectedOptionData[key])
			});
		}
		const updatedForm = {
			...createProductForm,
			phone_code: createProductForm.phone_code ? createProductForm.phone_code : phoneCodes[0].phone_code,
			currency_code: createProductForm.currency_code ? createProductForm.currency_code : currencyList[0].code,
		}

		if (updatedForm) {
			Object.keys(updatedForm).forEach(function (key) {
				formData.append(`${key}`, updatedForm[key])
			});
		}

		setShow(true);
		postsService
			.createPost(formData)
			.then(response => {

				if (response.success) {
					if (response.post_active) {
						setWalletBalance(response.data.wallet_balance);
						window.localStorage.setItem('total_balance', response.data.wallet_balance);
						if (response.data.phone_changed) {
							const UpdatedUser = {
								...loginUser,
								phone: response.data.user_new_phone,
							}
							window.localStorage.setItem('loggedUser', JSON.stringify(UpdatedUser))
						}
						setCreateProductForm({
							title: '',
							description: '',
							phone: response.data.phone_changed ? response.data.user_new_phone : loginUser?.phone,
							price: '',
						});
						setLoaded(true);
						navigate({
							pathname: '/listed',
							postData: response.data
						})
					} else {

						setPostID(response.data.id)
						setPostTempData(response.data)
						setTotalAmount(response.credit_amount);
						setCredit(response.credit_needed);
						setPaymentModal(true);
						setLoaded(true);
					}

				} else {
					setLoaded(true);
					addToast(response.message, { appearance: 'error' })
				}
			})
			.catch(error => {

				if (error.response) {
					if (error.response.status === 422) {
						setValidationError(
							error.response.data.errors
						)

						window.scrollTo(0, 350)
						setLoaded(true);
					}
					else {
						addToast(error.response.data.message, { appearance: 'error' })
						setLoaded(true);
					}
					setShow(false);
				}
			})
	}

	// local function to check element has error  
	const hasErrorFor = (field) => {
		if (validationError) {

			const error = validationError.find(element => String(element.field) === String(field));
			if (error)
				return true;
		} else {
			return false;
		}
	}

	// render validation error for element 
	const renderErrorFor = (field) => {
		if (hasErrorFor(field)) {
			const error = validationError.find(element => String(element.field) === String(field));
			return (
				<div className="error-wrapper">
					<span className='invalid-feedback'>
						<strong>{error.message}</strong>
					</span>
				</div>
			)
		}
	}

	// on select main category handler 
	const handleMainCategory = (e) => {
		e.preventDefault();
		let categoryID = e.currentTarget.dataset.id;
		var filtered = allProductCategories.filter(function (cat) {
			return String(cat.id) === String(categoryID);
		});
		setRate(filtered[0].rate);

		if (filtered[0].sub_category_fields) {
			setSubCategoris(filtered[0].sub_category_fields);
		} else {
			setSubCategoris([]);
		}
		setSelectedMainCategory(categoryID);
		setCreateProductForm({
			...createProductForm,
			category_id: categoryID,
		});


	}

	// on select sub category handler 
	const handleSelectSubCategory = (e, picker) => {
		e.preventDefault();
		const cfValue = e.currentTarget.dataset.option;
		const cfID = e.currentTarget.dataset.cfield;
		const newSel = {
			...selectedOptionData,
			[cfID]: cfValue
		}
		setSelectedOptionData(newSel);
		if (Object.entries(newSel).length === subCategoris.length) {
			setFilledSubcategory(true)
		}

	}

	// handle input 
	const handleInputSubCategory = (e, picker) => {

		var cfValue = e.currentTarget.value;
		const cfID = e.currentTarget.dataset.cfield;

		const newSel = {
			...selectedOptionData,
			[cfID]: cfValue
		}
		setSelectedOptionData(newSel);
		if (Object.entries(newSel).length === subCategoris.length) {
			setFilledSubcategory(true)
		}

	}

	// handle datepicker  
	const handleDatepickerValue = (event, picker) => {

		var cfID = event.currentTarget.dataset.cfield;
		var startDate = picker.startDate.format('MM/DD/YYYY');
		var endDate = picker.endDate.format('MM/DD/YYYY');
		var cfValue = `${startDate} to ${endDate}`;
		const type = event.currentTarget.dataset.type;
		if (type === 'date') {
			cfValue = picker.startDate.format('MM/DD/YYYY')
		}
		const newSel = {
			...selectedOptionData,
			[cfID]: cfValue
		}
		setSelectedOptionData(newSel);

	}

	// handle year of production selection 
	const handleYear = (event) => {

		var cfID = event.currentTarget.dataset.cfield;
		var cfvalue = event.currentTarget.value;
		var cfType = event.currentTarget.dataset.type;

		var newVal = '';
		if (cfType === "min") {
			setMinYear(cfvalue)
			newVal = `${cfvalue} - ${maxYear}`
		} else if (cfType === "max") {
			setMaxYear(cfvalue)
			newVal = `${minYear} - ${cfvalue}`
		}

		const newSel = {
			...selectedOptionData,
			[cfID]: newVal,
		}
		setSelectedOptionData(newSel);

	}

	// filter phone codes 
	const handlefilter = (e) => {

		var str = e.target.value || '';
		setFilterString(str);
		const lowercasedFilter = str.toLowerCase();
		const filteredData = phoneCodes.filter(item => {
			return item.phone_code.toLowerCase().includes(lowercasedFilter);
		});
		// filteredPhoneCodes,setFilteredPhoneCodes
		setFilteredPhoneCodes(filteredData)

	}

	// comman onChange handler of form 
	const handleFromChange = (e) => {
		const { name, value: newValue, type } = e.target;
		const value = type === 'number' ? +newValue : newValue;

		if (String(name) === 'title') {
			if (value.length <= 50) {
				setTitleTxtcounter(value.length);
				setCreateProductForm({
					...createProductForm,
					[name]: value,
				});

			}
		}
		else {
			setCreateProductForm({
				...createProductForm,
				[name]: value ? value : '',
			});

		}


	}

	const findByUniqueId = (arr, uniqueId) => arr.findIndex((item) => item.uniqueId === uniqueId)

	// retry image upload 
	const retryImageUpload = async (uniqueId) => {

		setPostImages(prevImgs => {
			let idx = prevImgs.findIndex((img) => img.uniqueId === uniqueId)
			let updatedArr = [...prevImgs]
			updatedArr[idx]['failed'] = false
			startUploadingImages([updatedArr[idx]])
			return updatedArr
		})

	}

	// upload  multiple image handler  
	const uploadMultipleFiles = async (e) => {
		if ((e.target.files.length + postImages.length) > 10) {
			addToast(t('You can upload up to 10 images.'), { appearance: 'error' })
		}
		else {
			for (let i = 0; i < e.target.files.length; i++) {
				if (!e.target.files[i].name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|BMP|bmp)$/)) {
					// this.setState({ invalidImage: 'Please select valid image.' });
					addToast(t('Files should be in jpg,jpeg,png,png,bmp format'), { appearance: 'error' });
					// alert("Invalid Image")
					return false;
				}
			}

			setPostImages((prevImgs) => {
				let newArr = [...prevImgs]
				for (let i = 0; i < e.target.files.length; i++) {

					newArr = [
						...newArr,
						{
							id: 0,
							imagePreview: URL.createObjectURL(e.target.files[i]),
							image: e.target.files[i],
							uploaded: 0,
							completed: false,
							failed: false,
							type: 'NEW',
							uniqueId: nanoid(10),
						},
					]
				}
				startUploadingImages([...newArr])
				return newArr
			})
		}
	}

	// track image upload progress
	const uploadProgress = (progress, uniqueId) => {
		let { total, loaded } = progress
		let inPercentage = Math.ceil((loaded * 100) / total)
		setPostImages((prevImgs) => {
			let imgs = [...prevImgs]
			let idx = findByUniqueId(prevImgs, uniqueId)

			if (idx > -1) {
				imgs[idx]['uploaded'] = inPercentage
			}

			return imgs
		})
	}

	//start  upload  on retry
	const startUploadingImages = async (imageArr) => {

		for (let i = 0; i < imageArr.length; i++) {

			setIsImageUploading(true);

			if (imageArr[i].uploaded !== 0 || imageArr[i].failed) continue

			new Compressor(imageArr[i].image, {
				quality: 0.6,
				success(compressedResult) {

					let formData = new FormData()
					formData.append(`pictures[${i}]`, compressedResult, compressedResult.name);
					formData.append(`position[${i}]`, imageArr[i].uniqueId);
					formData.append(`uniqueId[${i}]`, imageArr[i].uniqueId);

					axios
						.post(baseUrl + `/upload/images`, formData, {
							headers: token ? { Authorization: `Bearer ${token}` } : {},
							onUploadProgress: (progress) =>
								uploadProgress(progress, imageArr[i].uniqueId),
						})
						.then((result) => {
							if (result?.data) {
								setPostImages((prevImgs) => {

									setIsImageUploading(false);

									let imgs = [...prevImgs]
									let idx = findByUniqueId(prevImgs, imageArr[i].uniqueId)
									if (idx > -1) {
										imgs[idx]['completed'] = true
										// eslint-disable-next-line array-callback-return
										result.data.data.map((img) => {
											imgs[idx]['id'] = img.id
										})
									}

									return imgs
								})
							}
						})
						.catch(error => {
							if (error.response) {
								setLoaded(true);
								setIsImageUploading(false);
								setPostImages((prevImgs) => {
									let imgs = [...prevImgs]
									let idx = findByUniqueId(prevImgs, imageArr[i].uniqueId)
									if (idx > -1) {
										imgs[idx]['failed'] = true
										imgs[idx]['uploaded'] = 0
									}
									return imgs
								})
								addToast(
									<>
										<div className="toaster-wrapper">
											<div className="toaster-image-wrap">
												<Image src={imageArr[i]['imagePreview']} alt="" className="img-fluid" />
											</div>
											<strong>{t('Failed to upload this image, you can retry.')}</strong>
										</div>
									</>,
									{ appearance: 'error', autoDismissTimeout: 5000 });

							}
						})
				}
			});
		}
	}


	// creadit wallet api 
	const CreditWallet = async (data) => {

		walletServices.setToken(token);
		const newWalletCredit = {
			total_amount: parseFloat(totalAmount).toFixed(2),
			payment_method: 'paypal',
			payment_data: data,
			credit: credit,
			// post_id:postID,
		}
		setLoaderdata({
			title: t('Please wait'),
			message: t('Please Wait processing your payment'),
		});
		setShowLoader(true)
		await walletServices
			.creditWallet(newWalletCredit)
			.then(response => {
				if (response.success) {
					setPaymentModal(false);
					window.localStorage.setItem('total_balance', `${response.wallet_balance}`);
					setWalletBalance(`${response.wallet_balance}`);
					setPaymentMethod('');
					setShowLoader(false)
					setCompleted(false);
					setShow(true);
					markActivePost(postID);


				}
			})
			.catch(error => {
				setShowLoader(false)
				setPaymentModal(false);
				setLoaded(true);
				if (error.response) {
					if (error.response.status === 422) {
						setValidationError({
							errors: error.response.data.data
						})
					}
					else {
						// addToast(error.response.data.message, { appearance: 'error' })
						swalWithBootstrapButtons.fire(
							t('Error'),
							error.response.data.message,
							'error'
						)
					}

				}
			})
	}

	// make post active 
	const markActivePost = (postID) => {
		postsService.setToken(token)
		setPaymentModal(false);
		setLoaded(false);
		postsService
			.activatePost(postID)
			.then(response => {
				setLoaded(true);
				if (response.success) {
					setWalletBalance(response.data.wallet_balance);
					window.localStorage.setItem('total_balance', response.data.wallet_balance);
					setShow(false);
					navigate('/listed', { state: { postData: response.data } });

				}
			})
			.catch(error => {
				setLoaded(true);
				if (error.response) {
					swalWithBootstrapButtons.fire(
						t('Error'),
						error.response.data.message,
						'error'
					)
					navigate('/product/add');
					window.open(window.location.href, "_self")
				}
			})
	}

	// handle payment on success
	const onSuccess = (payment) => {

		setLoaded(false);
		CreditWallet(payment).then((response) => {
		}).catch((error) => {
			setLoaded(true);
		});
	}

	// handle payment failure 
	const onCancel = (data) => {
		// addToast(`The payment was cancelled! `, { appearance: 'error' });
		swalWithBootstrapButtons.fire(
			t('Error'),
			t('The payment was cancelled!'),
			'error'
		)
	}

	// handle payment error 
	const onError = (err) => {
		swalWithBootstrapButtons.fire(
			t('Error'),
			`${t('Error')} ${err}`,
			'error'
		)
	}

	// remove post image 
	const removePostImage = (sortIndex, id) => {
		const filteredAry = postImages.filter((image, i) => parseInt(i) !== parseInt(sortIndex));
		setPostImages(filteredAry)
		if (filteredAry.length < 9)
			setMaxFlieUploadError(false)

	}

	// on sort 
	const onSortEnd = ({ oldIndex, newIndex }) => {
		const newArr = arrayMove(postImages, oldIndex, newIndex)
		setPostImages(newArr);
	};


	const DragHandle = sortableHandle(() => <span className="drag-handle"><i className="fa fa-arrows-alt"></i></span>);

	// Sortable handler (Drag)
	const SortableItem = sortableElement(({ value, sortIndex, removePostImage }) =>

		<Col xs={12} sm={4} md={3} lg={3}>

			{value.failed
				?
				<>
					<OverlayTrigger
						placement="top"
						overlay={
							<Tooltip className="tooltip-error" id={`tooltip-edit`}>
								{t('Failed to upload')}
							</Tooltip>
						}
					>

						<Button onClick={() => retryImageUpload(value.uniqueId)} className="btn btn-outline-secondary retry-button" > {t('Retry')}</Button>
					</OverlayTrigger>
					<div className="image-uploded">
						<div onClick={() => removePostImage(sortIndex, value.uniqueId)} className="remove-image cp">
							<i className="fa fa-times" aria-hidden="true"></i>
						</div>
						<div className="image-upload">
							<Image src={value?.imagePreview} alt="" className="img-fluid" />
						</div>
					</div>
				</>
				:
				<>
					{value.completed && <DragHandle />}

					{value.completed && (<span className="image-loading">
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip className="tooltip-success" id={`tooltip-edit`}>
									{t('Uploaded')}
								</Tooltip>
							}
						>
							<i className="fa fa-check pointer" aria-hidden="true"></i>
						</OverlayTrigger>
					</span>)}


					<div className="image-uploded">
						{/* {value.completed && 
								( */}
						<div onClick={() => removePostImage(sortIndex, value.uniqueId)} className="remove-image cp">
							<i className="fa fa-times" aria-hidden="true"></i>
						</div>
						{/* )} */}
						<div className="image-upload">
							{!value.completed &&
								<div className="progress-holder">
									<ProgressBar className="images-progress-bar" now={value.uploaded} key={value.uniqueId} />
								</div>
							}
							<Image src={value?.imagePreview} alt="" className="img-fluid" />
							{(value.completed) && (
								<>
									{(parseInt(sortIndex) === 0) && (
										<div className="cover-photo"><p>{t('Cover')}</p></div>
									)}
								</>
							)}
						</div>

					</div>
				</>
			}

		</Col>

	);


	const SortableContainer = sortableContainer(({ children }) => {
		return <span>{children}</span>
	});


	// on complete crop
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	const onSelectFile = (e) => {

		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => {
				setUpImg(reader.result)
			}

			);
			reader.readAsDataURL(e.target.files[0]);
			setCropper(true);
		}


	};

	// cropeer preview handler 
	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				upImg,
				croppedAreaPixels,
				rotation
			)
			setCroppedImage(croppedImage)
		} catch (e) {
			console.error(e)
		}
	}, [croppedAreaPixels, rotation])

	// onSelect handler fro phone code 
	const handlePhoneCodeSelect = (e) => {


		// update the value of phone_code
		const pcode = e.currentTarget.dataset.value;
		const pflag = e.currentTarget.dataset.image;
		setSelectedPhoneOption({
			phoneCode: pcode,
			flag: pflag
		})

		setCreateProductForm({
			...createProductForm,
			phone_code: pcode,
		});


	}

	// check errors locally 
	const checkErrorForRange = (subcategory) => {
		var selectedItemArray = Object.entries(selectedOptionData);
		let selelectedOptionID = '';

		selectedItemArray.forEach(element => {
			if (parseInt(element[0]) === parseInt(subcategory.id)) {
				selelectedOptionID = element[1]
			}
		});
		var errorClass = '';
		var errorMessage = '';
		if (validationError) {
			errorClass = `${hasErrorFor(`cf.${subcategory.id}`) ? 'text-danger' : 'valid'}`
			errorMessage = renderErrorFor(`cf.${subcategory.id}`);
		}
		var errorss = ''
		var minval = ''
		var maxval = ''
		if (subcategory.type === "date_range") {
			if (selelectedOptionID.includes("-")) {
				if (selelectedOptionID) selelectedOptionID = selelectedOptionID.split('-');

				// if(selelectedOptionID[0] && selelectedOptionID[0].trim() == '' && selelectedOptionID[1].trim() != ''){
				//     // handlemin(selelectedOptionID[1].trim())
				//     errorss = 'select min year range';
				// }
				// if(selelectedOptionID[0] && selelectedOptionID[0].trim() != '' && selelectedOptionID[1].trim() == ''){
				//     // handlemax(selelectedOptionID[0].trim())
				//     errorss = 'select max year range';
				// }
				// if(selelectedOptionID[0] && selelectedOptionID[0].trim() > selelectedOptionID[1].trim()){
				//     errorss = 'min year range must be greather than max year';
				// }
			} else {
				selelectedOptionID = '';
			}

			if (selelectedOptionID[0]) {
				minval = selelectedOptionID[0].trim()
			}
			if (selelectedOptionID[1]) {
				maxval = selelectedOptionID[1].trim()
			}

			if (maxval < minval) {
				errorss = t(t('To year must be greater or equals than from year'));
			}


			if (maxval < 1500) {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t(t('To year should be greater than or equals 1500'));
			}

			if (minval > new Date().getFullYear()) {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t('From year should be lesser than') + new Date().getFullYear();
			}
			if (maxval > new Date().getFullYear()) {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t('To year should be lesser than') + new Date().getFullYear();
			}

			if (maxval.length < 4 || maxval.length > 4) {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t(t('To year should be 4 digits'));
			}

			if (maxval === '') {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t(t('Please enter to year'));
			}

			if (minval < 1500) {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t(t('From year should be greater than or equals 1500'));
			}

			if (minval > new Date().getFullYear()) {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t('From year should be lesser than or equals') + new Date().getFullYear();
			}

			if (minval.length < 4 || minval.length > 4) {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t(t('From year should be 4 digits'));
			}



			if (minval === '') {
				// handlemin(selelectedOptionID[1].trim())
				errorss = t(t('Please enter from year'));
			}
			if (minval === '' && maxval === '') {
				// handlemin(selelectedOptionID[1].trim())
				errorss = '';
			}
		}
		var selectedOption = selelectedOptionID;
		if (subcategory.type === "select") {
			if (selelectedOptionID) {
				selectedOption = subcategory.options.filter(function (opt) {
					return parseInt(opt.id) === parseInt(selelectedOptionID);
				});
				selectedOption = selectedOption[0];
			}
		}
		var dateIntitilize = '';
		if (subcategory.type === "date") {
			dateIntitilize = selelectedOptionID ? {
				singleDatePicker: true,
				showDropdowns: true,
				startDate: selelectedOptionID,
				minYear: 1901,
				maxYear: parseInt(moment().format('YYYY'), 10),
			} :
				{
					singleDatePicker: true,
					showDropdowns: true,
					startDate: moment().toDate(),
					minYear: moment().year(),
					maxYear: parseInt(moment().format('YYYY'), 10),
				}
		}

		const data = {
			minval: minval,
			maxval: maxval,
			errorss: errorss,
			errorClass: errorClass,
			errorMessage: errorMessage,
			selectedOption: selectedOption,
			dateIntitilize: dateIntitilize,
		}
		return data;


	}

	// handle payment method
	const handlePaymentMethod = async (e) => {
		setLoaded(false);
		if (e.currentTarget.dataset.value === 'paypal') {
			setPaymentMethod(e.currentTarget.dataset.value)
			setLoaded(true);
		} else {

			const data = {
				amount: parseFloat(totalAmount).toFixed(2),
				payment_method: e.currentTarget.dataset.value,
				credit: credit,
				post_id: postID,
				success_url: 'product/add',
				failed_url: 'product/add',

			}
			setPaymentMethod(e.currentTarget.dataset.value)
			const stripe = await stripePromise;

			walletServices.setToken(token);
			const response = await walletServices.createPaymentIntent(data);
			const session = await response.data;
			const result = await stripe.redirectToCheckout({
				sessionId: session.session_id,
			});

			if (result.error) {
				swalWithBootstrapButtons.fire(
					t('Payment server error!'),
					result.error.message,
					'error'
				)
			}


		}
		// setPaymentModal(true);
	}




	return (
		<>
			<SeoTags title={'Miltrade | List product'}
				description={''}
				category={''}
				url={window.location.href}
				image={''}
			/>
			<Header data={isHomePage} checkAuth={true} />
			<PageLoader isLoaded={loaded} />
			<div className="footer-bottom1">
				<div className="product-listing">
					<Row className="no-gutters">
						<Col xs={12} sm={12} md={12} lg={12}>
							<div className="padding1">
								<div className="breadcrum pt-2">
									<Breadcrumb>
										<Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
										<Breadcrumb.Item active>{t('create your listing')}</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={12} md={12} lg={6}>
							<div className="padding1">
								<div className="side1">
									<div className="listing-title select-category mb-3">
										<Row>
											<Col xs={12} sm={12} md={12} lg={12} className="">
												<h2>{t('List your item')}</h2>
												<h3> {t('Select the category of your item')} <span className="valadition">*</span></h3>
												<p>{t('Choose the category of your item so we can list your item on the right page.')}</p>
											</Col>
											{/* <Col xs={2} sm={2} md={1} lg={1} className="">
												<Image src="/images/check_mark.png" alt="user" />
											</Col> */}
										</Row>
									</div>
									<ProductCategories allProductCategories={allProductCategories}
										handleMainCategory={handleMainCategory}
										selectedMainCategory={selectedMainCategory}
									/>
									<div className="listing-title mt-3">
										<Row>

											<Col xs={12} sm={12} md={12} lg={12} className="">
												<h3> {t('Specify the details of your item')} <span className="valadition">*</span></h3>
												<p>{t('Add as many details of your item as possible. Finding your item will be easier and quicker for potential buyers when details are provided.')}</p>
											</Col>
											{/* <Col xs={2} sm={2} md={1} lg={1} className="">
												<div style={{display:filledSubcategory?'block':'none'}} ><Image src="/images/check_mark.png" alt="user" /></div>
											</Col> */}
										</Row>
									</div>
									<div className="product-details-table mt-3">
										<Row>
											<Col xs={12} sm={12} md={12} lg={12}>
												<div className="table-responsive">
													<Table bordered size="sm">
														<tbody>
															{subCategoris.map((subcategory, index) => (
																<tr key={index}>
																	<td style={{ width: "40%" }}>
																		<div className="post-sub-category-name cat-title">
																			{subcategory.name}<span className="valadition">*</span>
																		</div>
																		<input type="hidden" name={subcategory.id} value={subcategory.value} />
																	</td>
																	<td className="list-dropdown" style={{ width: "60%" }}>
																		{subcategory.type === "date_range" && (
																			<>

																				<div>
																					<Col xs={12} sm={12} md={12} lg={12} className="dateRange-input">
																						<Row>
																							<Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">
																								<Form.Control onChange={handleYear}
																									placeholder={t('From Year')}
																									type="text"
																									data-cfield={subcategory.id}
																									data-type='min'
																									value={checkErrorForRange(subcategory).minval}
																									className="form-control" >
																								</Form.Control>
																							</Col>
																							<Col xs={12} sm={12} md={6} lg={6} className="dateRange-input">

																								<Form.Control onChange={handleYear}
																									placeholder={t('To Year')}
																									data-cfield={subcategory.id}
																									type="text"
																									value={checkErrorForRange(subcategory).maxval}
																									data-type='max'
																									className="form-control" >
																								</Form.Control>
																							</Col>
																						</Row>
																					</Col>

																				</div>
																				<span className="invalid-feedback">
																					{checkErrorForRange(subcategory).errorMessage || checkErrorForRange(subcategory).errorss}
																				</span>
																			</>
																		)}
																		{subcategory.type === "select" && (
																			<>
																				<Dropdown>
																					<div className="subCategoryWrapper">
																						<Dropdown.Toggle variant="success" id="dropdown-basic"><div id={subcategory.id} className={`dropbtn ${checkErrorForRange(subcategory).errorClass}`}>
																							{checkErrorForRange(subcategory).selectedOption ? checkErrorForRange(subcategory).selectedOption.title : checkErrorForRange(subcategory).errorMessage ? checkErrorForRange(subcategory).errorMessage : `${t('Select')} ${subcategory.name}`}</div></Dropdown.Toggle>
																						<Dropdown.Menu>
																							{subcategory.options.map((option, i) =>
																								<Dropdown.Item onClick={handleSelectSubCategory}
																									data-cfield={subcategory.id}
																									data-selected={option.title}
																									data-option={option.id}
																									data-image={option.image}
																									key={i} > {option.title}
																								</Dropdown.Item>
																							)}
																						</Dropdown.Menu>
																					</div>
																				</Dropdown>

																			</>

																		)}
																		{(subcategory.type === "text" || subcategory.type === "email") && (
																			<>
																				<div>
																					<Form.Group >
																						<Form.Control
																							onChange={handleInputSubCategory}
																							type={subcategory.type === "text" ? 'text' : 'email'}
																							data-cfield={subcategory.id}
																							className={checkErrorForRange(subcategory).errorClass}
																							data-pvalue={checkErrorForRange(subcategory).selectedOption}
																							value={checkErrorForRange(subcategory).selectedOption}
																							placeholder={`${t('Enter')} ${subcategory.name}`}
																						>
																						</Form.Control>
																					</Form.Group>
																				</div>
																			</>
																		)}
																		{(subcategory.type === "number") && (
																			<>
																				<div>
																					<Form.Group >
																						<Form.Control
																							onChange={handleInputSubCategory}
																							type="number"
																							data-cfield={subcategory.id}
																							className={checkErrorForRange(subcategory).errorClass}
																							data-pvalue={checkErrorForRange(subcategory).selectedOption}
																							value={checkErrorForRange(subcategory).selectedOption}
																							placeholder={`${t('Enter')} ${subcategory.name}`}
																						>
																						</Form.Control>
																					</Form.Group>
																				</div>
																			</>
																		)}
																		{(subcategory.type === "date") && (
																			<>
																				<div>
																					<DateRangePicker onApply={handleDatepickerValue}
																						initialSettings={checkErrorForRange(subcategory).dateIntitilize}
																					>

																						<Form.Control name={subcategory.name}
																							data-type="date"
																							data-cfield={subcategory.id}
																							type="text"
																							className={checkErrorForRange(subcategory).errorClass} />

																					</DateRangePicker>
																					{checkErrorForRange(subcategory).errorMessage}
																				</div>
																			</>
																		)}
																	</td>
																</tr>
															))}
														</tbody>
													</Table>
												</div>
											</Col>
										</Row>
									</div>



									<div className="listing-title price mt-3">
										<div className="hide-price">
											<Row>
												<Col xs={12} sm={12} md={12} lg={12} className="">
													<div className={openToOffers ? 'ovelay-baackground' : ''}></div>
													<h3> {t('Pricing method')} <span className="valadition">*</span></h3>
													<p className="mb-3">{t('Set the price of your item. You can fill in a price in EURO, USD or GBP or you can choose to only receive offers. Bids can be placed through the chatbox or phone.')}</p>
													<h4> {t('Enter the price of your item')}</h4>
												</Col>
												{/* <Col xs={1} sm={1} md={1} lg={1} className="">
													<div style={{display:createProductForm?.price && !openToOffers?'block':'none'}}>
														<Image src="/images/check_mark.png" alt="user" />
													</div>
												</Col> */}
												<Col xs={2} sm={1} md={1} lg={1} className="">
													<div className="radio">
														<input id="radio-1" name="radio" type="radio" defaultChecked={true} onClick={() => setOpenToOffers(false)} />
														<label htmlFor="radio-1" className="radio-label"></label>
													</div>
												</Col>
												<Col xs={10} sm={11} md={11} lg={11} className="">
													<div className="post-add listing-number-hide mt-3">
														<Form className="products-search price-form-control">
															<div className="input-group ">

																<select className="form-control1" name="currency_code" onChange={handleFromChange}>
																	{currencyList?.map((currency, i) => (
																		<option key={i} value={currency?.code}>{`${currency.symbol} (${currency.code})`}</option>
																	))}
																</select>
																{openToOffers ? (
																	<Form.Control
																		className={`form-control2 ${hasErrorFor('price') ? 'is-invalid' : ''}`}
																		type="number"
																		name="price"
																		// disabled={true}
																		value="Open to Offer"
																		placeholder=""
																	/>
																) : (

																	<Form.Control
																		className={`form-control2 ${hasErrorFor('price') ? 'is-invalid' : ''}`}
																		type="number"
																		name="price"
																		min="0"
																		disabled={false}
																		onChange={handleFromChange}
																		value={createProductForm?.price}
																		placeholder={t('enter a price')}
																	/>
																)}

															</div>
															{renderErrorFor('price')}
														</Form>
													</div>
												</Col>

											</Row>
										</div>
									</div>
									<div className="listing-title price mt-3">
										<Row>
											<Col xs={2} sm={1} md={1} lg={1} className="">
												<div className="radio-btn">
													<input id="radio-2" name="radio" type="radio" onClick={() => setOpenToOffers(true)} defaultChecked={false} />
													<label htmlFor="radio-2" className="radio-label"></label>
												</div>
											</Col>
											<Col xs={10} sm={11} md={11} lg={11} className="">
												<div className="hide-price2">
													<div className={!openToOffers ? 'ovelay-baackground2' : ''}></div>
													<h4> {t('I am open to offers')}</h4>
												</div>
											</Col>
											{/* <Col xs={1} sm={1} md={1} lg={1} className="">
													<div style={{display:openToOffers?'block':'none'}}>
														<Image src="/images/check_mark.png" alt="user" />
													</div>
												</Col> */}



										</Row>
									</div>

									<div className="listing-title mt-3">
										<Row>
											{/* <Col xs={2} sm={2} md={1} lg={1} className="pr-0">
												{(filledSubcategory && createProductForm?.title
													&& createProductForm?.description
													&& croppedImage && formImages.length > 0
													&& createProductForm.phone > 0 ) && (<Image src="/images/check_mark.png" alt="user" />)}	
												</Col> */
											}
											{/* <Col xs={12} sm={12} md={12} lg={12} className="p-0">
												{(filledSubcategory && createProductForm?.title
													&& createProductForm?.description
													&& croppedImage && formImages.length > 0
													&& createProductForm.phone > 0 ) && (<h5> Your listing is ready to be published!</h5>)}
												</Col> */
											}
										</Row>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={12} sm={12} md={12} lg={6}>
							<div className="side2 owl-carousel-arrow">
								<div className="padding">
									<div className="listing-title mt-3">
										<Row>
											<Col xs={12} sm={12} md={12} lg={12} className="">
												<h6>{t('items marked with * are obligatory fields')}</h6>
												<h3> {t('Upload Images')} <span className="valadition">*</span></h3>
												<p className={`photo-text`}> {t('Upload up to 10 photos of your items.')} <br />
													{t('Tip: include photos of details and damages, and photos from different angles.')}</p>
											</Col>

											<Col xs={12} sm={12} md={12} lg={12} className="">
												<Row className="reorder-fixer">
													<SortableContainer onSortEnd={onSortEnd} distance={1} axis="xy" useDragHandle  >
														{postImages?.map((file, index) => (
															<SortableItem key={`item-${index}`} index={index} type={'new'} sortIndex={index} value={file} removePostImage={removePostImage} />
														))}
													</SortableContainer>
													<Col xs={12} sm={12} md={3} lg={3}>
														{/* { !isImageUploading &&  ( */}
														<div className="image-upload file-upload">
															<label htmlFor="file-input">
																<i className="icon-upload"></i>
															</label>
															<input id="file-input"
																type="file"
																name="pictures"
																multiple={true}
																onClick={(e) => (e.target.value = '')}
																onChange={uploadMultipleFiles}
															/>
														</div>
														{/* )} */}
													</Col>
												</Row>
												{renderErrorFor('pictures')}
											</Col>

										</Row>
									</div>
									<div className="listing-title">
										<Row>
											<Col xs={12} sm={12} md={12} lg={12} className="">
												<h3> {t('Title')} <span className="valadition">*</span></h3>
												<p>{t('Add a title for your item. A clear and descriptive title increases the chances of your item to be found and viewed by potential buyers.')} </p>
											</Col>
											{/* <Col xs={2} sm={2} md={1} lg={1} className="">
												<div style={{display:createProductForm?.title?'block':'none'}}>
													<Image src="/images/check_mark.png" alt="user" />
												</div>
											</Col> */}
											<Col xs={12} sm={12} md={12} lg={12} className="">
												<div className="product-name">
													<Form>
														<Form.Group controlId="">
															<Form.Control
																className={`${hasErrorFor('title') ? 'is-invalid' : 'valid'}`}
																type="text"
																name="title"
																onChange={handleFromChange}
																value={createProductForm.title ? createProductForm.title : ''}
																placeholder={t('Type Product Title')}
															/>
														</Form.Group>
														{renderErrorFor('title')}
													</Form>
												</div>

												<div className="character"> <span>{t('characters')} {titleTxtcounter}/50</span></div>
											</Col>

										</Row>
									</div>
									<div className="listing-title add-list-desc">
										<Row>
											<Col xs={12} sm={12} md={12} lg={12} className="">
												<h3> {t('Description')} <span className="valadition">*</span></h3>
												<p>{t('Add the description of your item. Provide as much information as possible, for example more information regarding the quality of the item, possible damages and shipping information.')}</p>
											</Col>
											{/* <Col xs={2} sm={2} md={1} lg={1} className="">
												<div style={{display:(createProductForm?.description?.length > 100)?'block':'none'}}>
													<Image src="/images/check_mark.png" alt="user" />
												</div>
											</Col> */}
											<Col xs={12} sm={12} md={12} lg={12} className="">
												<div className="product-name">
													<Form>
														<Form.Group controlId="">
															<Form.Control placeholder={t('Type Product Description')}
																name="description"
																className={`${hasErrorFor('description') ? 'is-invalid' : 'valid'}`}
																as="textarea"
																rows={3}
																onChange={handleFromChange}
																value={createProductForm.description ? createProductForm.description : ''}
															/>
														</Form.Group>
														{renderErrorFor('description')}
													</Form>
												</div>
											</Col>

										</Row>
									</div>
									<div className="listing-title mt-3">
										<Row>
											<Col xs={12} sm={12} md={12} lg={12} className="">
												<h3> {t('Contact information')}<span className="valadition">*</span></h3>
												<p> {t('Your contact information will be shown next to your listing. Please specifiy if you wish to hide your phone number. The standard setting is “show”.')} </p>

											</Col>
											{/* <Col xs={2} sm={2} md={1} lg={1} className="">
												<div style={{display:createProductForm?.phone?'block':'none'}}>
													<Image src="/images/check_mark.png" alt="user" />
												</div>
											</Col> */}
											<Col xs={12} sm={6} md={6} lg={6} className="">
												<h4 className="mt-3 post-phone"> {t('Your phone number')}</h4>
												<div className="listing-number-hide mt-3">
													<Form className="products-search">
														<div className="input-group mb-3">
															<Dropdown>
																<div className="subCategoryWrapper">
																	<Dropdown.Toggle className="mobile-number-left-dropdown " variant="success" id="dropdown-basic">
																		<div className={`dropbtn `}>{selectedPhoneOption && (
																			<>
																				{/* <Image className="rounded" src={selectedPhoneOption?.flag} alt={selectedPhoneOption?.flag} />  */}
																				<span> {selectedPhoneOption?.phoneCode}</span>
																			</>
																		)}</div>

																	</Dropdown.Toggle>
																	<Dropdown.Menu>
																		<div className="mobile-code-search">
																			<form className="">
																				<div className="form-group">
																					<div className="category-search-icon"><i className="icon-search"></i></div>
																					<input value={filterString} onChange={handlefilter} placeholder={t('search list')} type="text" id="formBasic" className="form-control" />
																				</div>
																			</form>
																		</div>
																		{filteredPhoneCodes?.map((code, i) =>
																			<Dropdown.Item onClick={handlePhoneCodeSelect}
																				data-image={code.flag}
																				data-value={code?.phone_code}
																				key={i} > {code?.phone_code}
																			</Dropdown.Item>
																		)}
																	</Dropdown.Menu>
																</div>
															</Dropdown>
															<Form.Control
																className={`form-control2 ${hasErrorFor('phone') ? 'is-invalid' : 'valid'}`}
																type={phoneHidden ? 'number' : 'password'}
																name="phone"
																min="0"
																onChange={handleFromChange}
																value={createProductForm.phone ? createProductForm.phone : ''}
																placeholder={t('Enter your phone number')}
															/>
															<div className="input-group-append">
																<Button data-phonehidden="tooltip" data-placement="top" title={t('On hide number Your phone number will not be visible in Ads')} onClick={() => setPhoneHidden(!phoneHidden)} className="btn btn-outline-secondary hide-number-btn" >{phoneHidden ? t('Hide') : t('Show')}</Button>
															</div>
														</div>
													</Form>
													{renderErrorFor('phone')}
												</div>
											</Col>
											<Col xs={12} sm={6} md={6} lg={6} className="">
												<h4 className="mt-3">{t('Your location')}</h4>
												<div className="list-loaction">
													<i className="icon-location mr-1"></i> <span>{loginUser?.address}</span>
												</div>
											</Col>
										</Row>
									</div>


								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className="publish-place-listion">
					<Row className="no-gutters">
						<Col xs={12} sm={12} md={12} lg={12}>

							<h4>{t('Publish your listing')}</h4>
							<p>{t('Ready to publish? Press the button below to place your listing. Your listing will be online  immediately and')}  <br /> {t('you can always edit the details  of your listing once it has been published.')} </p>
							{/* {!subscribedUser ?(<div className="list-cost">
														<b>Listing cost :</b> 
														<span className="cost"> {rate == 1 ?`${rate} credit`:`${rate} credits`} </span>
															<span className="tooltip1">
																<i className="icon-about_miltrade cp "></i>
																<div className="tooltiptext1">
																 <div className="product-info-box">
																 <h3>Credits</h3>
																 <p>As a seller you pay a small fee to list your item on Miltrade. The chart below shows the credit costs per listing. <Link to="/support">More information.</Link> </p>	
																 <div className="table-responsive">
																	<table className="table table-bordered">
																		<thead>
																			<tr>
																			<th scope="col">Category</th>
																			<th scope="col">Credits per listing</th>
																			</tr>
																		</thead>
																		<tbody>
																				<tr>
																					<td>Militaria</td>
																					<td>1 credit</td>
																				</tr>
																				<tr>
																				<td>Vehicles</td>
																					<td>20 credits</td>
																				</tr>
																				<tr>
																					<td>Parts</td>
																					<td>1 credit</td>
																				</tr>
																				<tr>
																					<td>Weapons</td>
																					<td>5 credits</td>
																				</tr>
																			</tbody>
																			</table>
																	</div> 
																 </div> 
																</div>
															</span>
														
													</div>):(
														<div className="list-cost">
														<b> You will save  </b> 
														
														<span className="cost"> 
														{rate == 1 ?`${rate} credit`:`${rate} credits`}</span> 
															<span className="tooltip1">
																<i className="icon-about_miltrade cp "></i>
																<div className="tooltiptext1">
																 <div className="product-info-box">
																 <h3>Credits</h3>
																 <p>As a seller you pay a small fee to list your item on Miltrade. The chart below shows the credit costs per listing. <Link to="/support">More information.</Link> </p>	
																 <div className="table-responsive">
																	<table className="table table-bordered">
																		<thead>
																			<tr>
																			<th scope="col">Category</th>
																			<th scope="col">Credits per listing</th>
																			</tr>
																		</thead>
																		<tbody>
																				<tr>
																					<td>Militaria</td>
																					<td>1 credit</td>
																				</tr>
																				<tr>
																				<td>Vehicles</td>
																					<td>20 credits</td>
																				</tr>
																				<tr>
																					<td>Parts</td>
																					<td>1 credit</td>
																				</tr>
																				<tr>
																					<td>Weapons</td>
																					<td>5 credits</td>
																				</tr>
																			</tbody>
																			</table>
																	</div> 
																 </div> 
																</div>
															</span> as verified dealer
														
													</div>
													
													
								)}	 */}

						</Col>
						<Col xs={12} sm={12} md={12} lg={12} className="">
							<div className="large-button mb-2 mt-3"> <Button onClick={handleSubmit} variant="primary" type="submit">{t('Create Listing')}</Button></div>
						</Col>

					</Row>
				</div>
				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Modal show={show} onHide={() => setShow(false)} dialogClassName="modal-90w  product-create-modal" size="lg">
								<Modal.Body>
									<Spinner animation="border" role="status">
										<span className="sr-only">{t('Loading...')}</span>
									</Spinner>
									<div className="product-create">
										<h3>{t('Uploading Your Ad...')}</h3>
										<p>{t('Your ad will be ready in a moment. Please do not close windows.')}</p>
									</div>
								</Modal.Body>
							</Modal>
						</Col>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Modal show={paymentModal} onHide={() => setPaymentModal(false)} dialogClassName="modal-90w" size="lg">
								<Modal.Header closeButton>
									<div className="text-center add-payment">
										<h5>{t('Payment for')} {parseInt(credit) === 1 ? `${credit} ${t('credits')}` : `${credit} ${t('credits')}`} </h5>
										{/* {totalAmount > 0  && (<span>You will be charged <strong style={{color:'var(--green)'}} >{loginUser?.currency_symbol} { totalAmount} </strong> </span>)}	 */}
									</div>
								</Modal.Header>
								<Modal.Body>
									<div className="payment-methods">
										<Row>
											<Col xs={12} sm={12} md={12} lg={12} >
												<div className="recommended payment-recommended">
													<h6>{t('Select a Payment method')}</h6>
													<ul>
														<li className='mb-2'>
															<PaypalExpressBtn env={env}
																client={client}
																currency={loginUser?.default_currency ? loginUser?.default_currency : 'EUR'}
																total={totalAmount ? totalAmount : 0.00}
																style={buttonStyles}
																onError={onError}
																onSuccess={onSuccess}
																onCancel={onCancel} />
														</li>

														<li><span onClick={handlePaymentMethod} data-value="card" className={`wallet-card-btn cp ${paymentMethod === 'card' ? 'active' : ''}`}><Image src="/images/payment-methods/card1.svg" alt="card" width="20px" /> Card</span></li>
														{loginUser?.default_currency === "EUR" && (
															<>
																<li><span onClick={handlePaymentMethod} data-value="ideal" className={`wallet-card-btn cp ${paymentMethod === 'ideal' ? 'active' : ''}`}><Image src="/images/payment-methods/ideal.webp" alt="ideal" width="20px" /> iDEAL</span></li>
																<li><span onClick={handlePaymentMethod} data-value="sofort" className={`wallet-card-btn cp ${paymentMethod === 'sofort' ? 'active' : ''}`}><Image src="/images/payment-methods/sofort.webp" alt="sofort" width="20px" /> Sofort</span></li>
																<li><span onClick={handlePaymentMethod} data-value="bancontact" className={`wallet-card-btn cp ${paymentMethod === 'bancontact' ? 'active' : ''}`}><Image src="/images/payment-methods/bancontact.webp" alt="bancontact" width="20px" /> Bancontact</span></li>
																<li><span onClick={handlePaymentMethod} data-value="giropay" className={`wallet-card-btn cp ${paymentMethod === 'giropay' ? 'active' : ''}`}><Image src="/images/payment-methods/giropay.webp" alt="giropay" width="20px" /> giropay</span></li>
															</>
														)}
													</ul>
												</div>
											</Col>
										</Row>
									</div>
								</Modal.Body>
							</Modal>
						</Col>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Modal show={cropper} onHide={() => setCropper(false)} dialogClassName="modal-90w " size="lg">
								<div className="image-crop-modal">
									<div className="cropWrapper">
										<Modal.Header closeButton></Modal.Header>
										<div className="modal-content">
											<div className="modal-body">
												<div className="crop-container">
													<Cropper
														image={upImg}
														crop={crop}
														zoom={zoom}
														aspect={4 / 3}
														onCropChange={setCrop}
														onCropComplete={onCropComplete}
														onZoomChange={setZoom}
													/>
												</div>
											</div>


										</div>
										<div className="controls">
											<RangeSlider
												value={zoom}
												min={1}
												max={3}
												step={0.01}
												onChange={changeEvent => setZoom(changeEvent.target.value)}
											/>
										</div>
										<div className="image-crop-btn">
											<Button onClick={() => { showCroppedImage(); setCropper(false) }} >{t('Save')} </Button>
										</div>
									</div>
								</div>
							</Modal>
						</Col>
					</Row>
				</Container>
				<MyLoader showLoader={showLoader}
					title={loaderdata.title}
					description={loaderdata.message}
				/>
			</div>
			<Footer />

		</>
	)
}
export default ProductCreate;