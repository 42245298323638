/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import PayPalServices from "../services/subscribe";
import { useToasts } from 'react-toast-notifications'
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from "./include/header";
import Footer from "./include/footer";
import SeoTags from './seo/seoTags'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';




const PayPalAuthTransaction = () => {
  const { t } = useTranslation();
  const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');
  const PayerID = new URLSearchParams(search).get('PayerID');
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'mr-2 btn btn-danger'
    },
    buttonsStyling: false
  })



  var { addToast } = useToasts();

  useEffect(() => {
    async function authPayment() {

      const auth_token = loginUser.access_token;

      await PayPalServices.setToken(auth_token);
      var newToken = {
        token: token,
        PayerID: PayerID,
      }

      PayPalServices
        .recurringPayment(newToken)
        .then(response => {
          if (response.success) {
            window.localStorage.setItem('issubscribed', true);
            const newUserData = {
              ...loginUser,
              issubscribed: true
            }
            window.localStorage.setItem('loggedUser', JSON.stringify(newUserData))
            swalWithBootstrapButtons.fire({
              title: t('Great! You are now a verified member on Miltrade!'),
              text: t("You are now a verified dealer. You can list unlimited listings without any charges."),
              icon: 'success',
              showCancelButton: true,
              cancelButtonText: t('Back to Home'),
              confirmButtonText: t('List your item for free'),
              closeOnClickOutside: false,
              allowOutsideClick: false,
              reverseButtons: true
            }).then((result) => {
              if (result.isConfirmed) {
                navigate({
                  pathname: `/product/add`,
                });

              } else if (
                result.dismiss === Swal.DismissReason.cancel
              ) {
                navigate(`/`);
              }
            })



          }
        })
        .catch(error => {
          if (error.response) {
            setTimeout(function () {
              addToast(t('Some Error Occured'), { appearance: 'error' });
              // navigate('/')
            }, 1000);
          }
        })
    }
    authPayment();
  }, []);






  return (
    <>
      <SeoTags title={`Miltrade | Payment success`}
        description={''}
        category={''}
        url={window.location.href}
        image={''}
      />
      <Header
        data={false}
        checkAuth={true}
      />
      <div className="footer-bottom1">
        <Container>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="login-modal">
                <div className="modal-body-heading text-center">
                  <div className="alert alert-info" role="alert">
                    {t("Please wait verifing your transaction")}
                    <div className="spinner-border ml-4" role="status">
                      <span className="sr-only">{t('Loading...')}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  )
}


export default PayPalAuthTransaction;

