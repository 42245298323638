/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useToasts } from 'react-toast-notifications'

import homePageServices from "../../services/home";
import profileService from '../../services/myProfile'
import registrationServices from "../../services/registration";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

import loginServices from "../../services/login";
import PageLoader from '../../components/pageLoader'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Swal from 'sweetalert2'
import { Auth } from '../../Contexts/AuthContext';
import SocialLogins from './SocialLogins';
import { useTranslation } from 'react-i18next';


require('dotenv').config();


const Authentication = () => {
    const { t } = useTranslation();
    const {
        setShowLoginModal,
        setWalletBalance,
        setNewMessageCount,
        setUserProfileData
    } = useContext(Auth);
    let location = useLocation();
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(true);
    const [socialError, setSocialError] = useState();
    const [userPassordModal, setUserPassordModal] = useState(false);

    const [setLoginUser] = useState();


    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            // cancelButton: 'mr-2 btn btn-danger'
        },
        buttonsStyling: false
    })

    // required info Modal Data  
    const [formData, setFormData] = useState();
    const [tempUserData, setTempUserData] = useState();
    const [cities, setCities] = useState([]);
    const [filterCities, setFilterCities] = useState([]);
    const [otherInfo, setOtherInfo] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [selectedPhoneOption, setSelectedPhoneOption] = useState();
    const [filteredPhoneCodes, setFilteredPhoneCodes] = useState()
    const [filterString, setFilterString] = useState('');
    const [lgShow, setLgShow] = useState(false);
    const [validationError, setValidationError] = useState();
    const [city, setCity] = useState();
    const [countryFlag, setCountryFlag] = useState();
    const [filterCountrytString, setFilterCountrytString] = useState('');
    const [filteredCountries, setFilteredCountries] = useState('');
    const [filterCityString, setFilterCityString] = useState('');
    const [consentTC, setConsentTC] = useState(false);

    // Registration 
    const query = new URLSearchParams(location.search);
    const referralCode = query.get('referral_code')
    const [showRegistrationModal, setShowRegistrationModal] = useState(false);


    // Forgot Password 
    const [forgotPassword, setForgotPassword] = useState(false);
    // Social Login 
    const [socialoginData, setSocialoginData] = useState({
        network: '',
        social_token: '',
        email: '',
        referral_code: referralCode,
    })

    // Reset Paassword
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const urlParams = new URLSearchParams(location.search)
    var resetToken = urlParams.get('_tn');
    var resetEmail = urlParams.get('_ac');


    // new message  count locally 
    const getMessagesCount = async () => {
        var token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : false;
        if (token) {
            homePageServices.setToken(token)
            homePageServices
                .getMessageCount()
                .then(response => {
                    setNewMessageCount(response.data.count);
                })
        }
    }

    useEffect(() => {
        async function fetchMessages() {
            await getMessagesCount();
            if (resetToken && resetEmail) {
                // setResetPasswordModal(true);
            }
        }
        fetchMessages();
    }, [location]);


    // handle Social Login

    const handleSocialLogin = (user) => {
        user = {
            ...user,
            referral_code: referralCode,
        }
        SocialLogin(user)
    }

    //check social auth
    const SocialLogin = async (data) => {
        setShowLoginModal(false)
        setShowRegistrationModal(false)
        setLoaded(false)

        await loginServices
            .socialLogin(data)
            .then(response => {


                if (response.success) {
                    if (response.data.is_data_missing) {
                        // onSuccessHide();
                        onSuccessModal(response.data);
                    } else {

                        setUserPassordModal(false)
                        setSocialoginData({
                            network: '',
                            social_token: '',
                        })
                        setWalletBalance(response.data.wallet_balance);
                        window.localStorage.setItem('loggedUser', JSON.stringify(response.data))
                        window.localStorage.setItem('isLoggedIn', true)
                        window.localStorage.setItem('total_balance', response.data.wallet_balance);
                        window.localStorage.setItem('token', response.data.access_token);
                        window.localStorage.setItem('issubscribed', response.data.issubscribed);
                        window.localStorage.setItem('default_currency', response.data.default_currency);
                        window.localStorage.setItem('currency_symbol', response.data.currency_symbol);

                        setUserProfileData({
                            first_name: response.data.first_name,
                            username: response.data.username,
                            last_name: response.data.last_name,
                            image: response.data.picture,
                        })
                        HandleLoggedUser(response.data)
                        if (location.pathname.includes("reset-password")) {
                            navigate('/');
                            window.location.reload();
                        } else {
                            window.open(window.location.href, "_self")

                        }

                    }
                } else {
                    if (response.email_needed) {
                        setSocialoginData({
                            network: response.network,
                            social_token: response.social_token,
                        })
                        setShowLoginModal(false)
                        setUserPassordModal(true);
                        setLoaded(true)

                    } else {
                        setUserPassordModal(false);
                        setLoaded(true);
                        swalWithBootstrapButtons.fire(
                            t('success'),
                            response.message,
                            'success'
                        )
                    }
                }




            })
            .catch(error => {
                setLoaded(true);
                if (error.response) {
                    if (error.response.status === 400) {
                        setSocialError(
                            error.response.data.message
                        )
                    }
                    else {
                        swalWithBootstrapButtons.fire(
                            t('Error'),
                            error.response.data.message,
                            'error'
                        )
                    }

                }
            })
    }

    const HandleLoggedUser = (user) => {
        setLoginUser(user);
    }

    const handleSocialLoginFailure = (err) => {
        console.log('error', err)
    }

    // Login Modal 
    const Login = (props) => {
        const { setWalletBalance, setUserProfileData, showLoginModal } = useContext(Auth);
        const [hideShow, setHideShow] = useState(false);
        const [validationError, setValidationError] = useState();
        const [loaded, setLoaded] = useState(true);
        const handleSocialLoginFailure = props.handleSocialLoginFailure;
        const handleSocialLogin = props.handleSocialLogin;
        const setShowRegistrationModal = props.setShowRegistrationModal;
        const [loginForm, setLoginForm] = useState({
            email: '',
            password: '',

        });

        const handleChange = (e) => {
            const { name, value: newValue, type } = e.target;
            const value = type === 'number' ? +newValue : newValue;
            setLoginForm({
                ...loginForm,
                [name]: value,
            });
        }
        const handleSubmit = (e) => {
            e.preventDefault();

            var newLogin = {
                email: loginForm ? loginForm.email : '',
                password: loginForm ? loginForm.password : '',
            }
            setLoaded(false)
            loginServices
                .logIn(newLogin)
                .then(response => {
                    if (response.success) {
                        if (response.data.is_data_missing) {
                            props.onSuccessHide();
                            props.onSuccessModal(response.data);
                        } else {

                            newLogin = {};
                            setLoginForm();
                            setLoaded(true)

                            setWalletBalance(response.data.wallet_balance);
                            window.localStorage.setItem('loggedUser', JSON.stringify(response.data))
                            window.localStorage.setItem('isLoggedIn', true)
                            window.localStorage.setItem('total_balance', response.data.wallet_balance);
                            window.localStorage.setItem('token', response.data.access_token);
                            window.localStorage.setItem('issubscribed', response.data.issubscribed);
                            window.localStorage.setItem('default_currency', response.data.default_currency);
                            window.localStorage.setItem('currency_symbol', response.data.currency_symbol);

                            setUserProfileData({
                                first_name: response.data.first_name,
                                username: response.data.username,
                                last_name: response.data.last_name,
                                image: response.data.picture,
                            })

                            if (location.pathname.includes("reset-password")) {
                                navigate('/');
                                window.location.reload();
                            } else {
                                window.open(window.location.href, "_self")
                                props.onSuccessHide();
                            }

                        }
                    }
                })
                .catch(error => {
                    console.log("error====>",error);
                    setLoaded(true);
                    if ("errorMsg" in error) {
                        swalWithBootstrapButtons.fire(
                            t('Error'),
                            error.errorMsg,
                            'error'
                        );
                        return;
                    }
                    if (error.response) {
                        if (error.response.status === 422) {
                            setValidationError(
                                error.response.data.errors
                            )
                        }
                        else {
                            swalWithBootstrapButtons.fire(
                                t('Error'),
                                error.response.data.message,
                                'error'
                            )
                        }

                    }
                })
        }
        const hasErrorFor = (field) => {
            if (validationError) {
                const error = validationError.find(element => String(element.field) === String(field));
                if (error)
                    return true;
            } else {
                return false;
            }
        }
        const renderErrorFor = (field) => {
            if (hasErrorFor(field)) {
                const error = validationError.find(element => String(element.field) === String(field));
                return (
                    <div className="error-wrapper">
                        <span className='invalid-feedback'>
                            <strong>{error.message}</strong>
                        </span>
                    </div>
                )
            }
        }
        const handleSignUp = (props) => {
            setShowLoginModal(false);
            setShowRegistrationModal(true)
        }

        return (
            <>
                <PageLoader isLoaded={loaded} />
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="user-login-modal">
                                <Modal.Header closeButton className="m-0">
                                </Modal.Header>
                                <div className="login-modal">
                                    <Image src="/images/slider-image/opening_up_the_border.webp" alt="image" className="blog-image img-fluid" />
                                    <Modal.Body>
                                        <div className="modal-body-heading">
                                            <h3>{t("Join Miltrade")}</h3>
                                            <p>{t("Start selling and growing your military collection today by joining our international platform. Sign up today, it's free!")} </p>
                                        </div>
                                        <div className="login-form">
                                            <div className="login-reg-button">
                                                <span className="login-btn cp active">{t("Sign in")}</span>
                                                <span onClick={handleSignUp} className="reg-btn cp">{t("Register")}</span>
                                            </div>
                                            <Form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-email"></span>
                                                        <input placeholder=" "
                                                            name="email"
                                                            className={`${hasErrorFor('email') ? 'is-invalid' : ''}`}
                                                            id="email"
                                                            autoComplete="off"
                                                            onChange={handleChange}
                                                            value={loginForm ? loginForm.email : ""}
                                                            type="email" />
                                                        <label>{t("Enter your Email address")}</label>
                                                    </div>
                                                    {renderErrorFor('email')}
                                                </div>
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-password"></span>
                                                        <input placeholder=" "
                                                            id="password"
                                                            type={hideShow ? 'text' : 'password'}
                                                            autoComplete="off"
                                                            name="password"
                                                            className={`${hasErrorFor('password') ? 'is-invalid' : ''}`}
                                                            value={loginForm ? loginForm.password : ""}
                                                            onChange={handleChange} />
                                                        <label >{t("Enter your password")}</label>
                                                        <div onClick={() => setHideShow(!hideShow)} className="eye-icon cp"><i className={hideShow ? 'icon-eye_hide field-icon' : 'icon-eye field-icon'}></i></div>
                                                    </div>
                                                    {renderErrorFor('password')}
                                                </div>
                                                <div className="remember-me">
                                                    <Row className="no-gutters">
                                                        <Col xs={6} sm={6} md={6} lg={6} >
                                                            <div className="checkbox">
                                                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                                                <span> {t("Remember me")}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xs={6} sm={6} md={6} lg={6} >
                                                            <div className="forgot-password">
                                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                                <a className="cp" onClick={() => { setForgotPassword(true); setShowLoginModal(false) }} >{t("Forgot password")}?</a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div className="large-button"><Button variant="primary" type="submit"> {t("Sign in")}</Button></div>
                                            </Form>
                                            <div className="social-login">
                                                <SocialLogins onSuccess={handleSocialLogin} onFailure={handleSocialLoginFailure} />
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </div>
                                <div className="footer-modal">
                                    <p>{t("By Creating an account, you agree to our")}
                                        <a target="_blank" href="/support?page=terms-of-service&title=Terms of service&topic=terms-and-conditions&ttitle=Terms and Conditions"> {t("User Agreement")}</a> {t("and acknowledge reading our")}
                                        <a target="_blank" href="/support?page=terms-of-service&title=Terms of service&topic=privacy-policy-1&ttitle=Privacy Policy"> {t("User Privacy Notice")}.</a>
                                    </p>
                                </div>

                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    const onSuccessModal = (data) => {
        setTempUserData(data);
        getInfoSet()
    }

    // get  data for update profile
    const getInfoSet = () => {
        setLoaded(false)
        loginServices
            .otherInfos()
            .then(response => {
                setLoaded(true);
                if (response.success) {
                    setOtherInfo(response.data)
                    getCities(response.data.country[0].country_code)
                    setFilteredPhoneCodes(response.data.country)
                    setFilteredCountries(response.data.country)
                    setSelectedPhoneOption({
                        phoneCode: response.data.country[0].phone_code,
                        flag: response.data.country[0].flag
                    })

                    setCountryFlag(response.data.country[0]);
                    setLgShow(true);
                }


            })
            .catch(error => {
                setLoaded(true);
                if (error.response) {
                    swalWithBootstrapButtons.fire(
                        t('Error'),
                        error.response.data.message,
                        'error'
                    )
                    // alert(error.response.data.message, { appearance: 'error' })
                }
            })


    }

    // save required data 
    const updateUserProfile = (e) => {
        e.preventDefault();
        setLoaded(false);
        const newData = {
            ...formData,
            phone_code: selectedPhoneOption.phoneCode,
            city_id: city.id,
            country_code: countryFlag.country_code,
            username: tempUserData?.username ? tempUserData?.username : formData?.username,

        }
        // saveCurrency(formData.default_currency)
        profileService.setToken(tempUserData.access_token);
        profileService
            .updateProfile(newData)
            .then(response => {
                if (response.success) {
                    setLoaded(true);
                    setLgShow(false);
                    setFormData(response.data);
                    const UpdatedUser = {
                        ...tempUserData,
                        first_name: response.data.first_name,
                        username: response.data.username,
                        last_name: response.data.last_name,
                        picture: response.data.photo,
                        default_currency: response.data.default_currency,
                        currency_symbol: response.data.currency_symbol,
                        phone_code: selectedPhoneOption.phoneCode,
                        phone: response.data.phone,
                        country_code: countryFlag.country_code,
                    }

                    window.localStorage.setItem('loggedUser', JSON.stringify(UpdatedUser))
                    setUserProfileData({
                        first_name: response.data.first_name,
                        username: response.data.username,
                        last_name: response.data.last_name,
                        image: response.data.photo,
                    })

                    setWalletBalance(tempUserData.wallet_balance);

                    window.localStorage.setItem('isLoggedIn', true)
                    window.localStorage.setItem('total_balance', tempUserData.wallet_balance);
                    window.localStorage.setItem('token', tempUserData.access_token);
                    window.localStorage.setItem('issubscribed', tempUserData.issubscribed);
                    window.localStorage.setItem('default_currency', tempUserData.default_currency);
                    window.localStorage.setItem('currency_symbol', tempUserData.currency_symbol);

                    setUserProfileData({
                        first_name: response.data.first_name,
                        username: response.data.username,
                        last_name: response.data.last_name,
                        image: response.data.picture,
                    })

                    swalWithBootstrapButtons.fire({
                        title: t('Registration completed!'),
                        text: t("Thank you for creating an account and confirming your details. Welcome to Miltrade!"),
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'ok',
                        closeOnClickOutside: false,
                        allowOutsideClick: false,
                        reverseButtons: true
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();

                        } else if (
                            result.dismiss === Swal.DismissReason.cancel
                        ) {

                        }
                    })
                    navigate('/');
                    setShowLoginModal(false);


                } else {
                    setLoaded(true);
                    swalWithBootstrapButtons.fire(
                        t('Error'),
                        response.result,
                        'error'
                    )
                }
            })
            .catch(error => {
                if (error.response) {
                    setLoaded(true);
                    if (error.response.status === 422) {
                        setValidationError(
                            error.response.data.errors
                        )
                    }
                    else {
                        // addToast(error.response.data.message, { appearance: 'error' })
                        swalWithBootstrapButtons.fire(
                            t('Error'),
                            error.response.data.message,
                            'error'
                        )
                    }

                }
            })
    }

    // phone number dropdown handler
    const handlePhoneCodeSelect = (e) => {

        // update the value of phone_code
        const pcode = e.currentTarget.dataset.value;
        const pflag = e.currentTarget.dataset.image;
        setSelectedPhoneOption({
            phoneCode: pcode,
            flag: pflag
        })
        setFormData({
            ...formData,
            phone_code: pcode,
        });

    }

    // Load  cities api
    const getCities = async (country_code, city_id) => {
        setLoaded(false);
        await profileService
            .getCities(country_code)
            .then(response => {
                setLoaded(true);
                if (response.success) {
                    setCities(response.result)
                    setFilterCities(response.result)
                    if (city_id) {
                        let tempData = response.result.find(c => parseInt(c.id) === parseInt(city_id));
                        if (typeof (tempData) != "undefined") {
                            setCity(tempData);
                        } else {
                            setCity(tempData);
                        }

                    } else {
                        let tempData = response.result[0];
                        setCity(tempData)
                    }


                } else {
                    // addToast(response.message,{ appearance: 'error' })
                    swalWithBootstrapButtons.fire(
                        t('Error'),
                        response.message,
                        'error'
                    )
                }

            })
            .catch(error => {
                setLoaded(true);
                if (error.response) {
                    if (error.response.status === 422) {
                        //  addToast(error.response.data.data, { appearance: 'error' })
                        swalWithBootstrapButtons.fire(
                            t('Error'),
                            error.response.data.data,
                            'error'
                        )
                    }
                    else {
                        // addToast(error.response.data.message, { appearance: 'error' })
                        swalWithBootstrapButtons.fire(
                            t('Error'),
                            error.response.data.message,
                            'error'
                        )
                    }

                }
            })
    }

    // set required data modal form 
    const handleOtherFromChange = (e) => {
        const { name, value: newValue, type } = e.target;
        const value = type === 'number' ? +newValue : newValue;
        if (name === 'country_code') {
            getCities(value, null)
        }
        setFormData({
            ...formData,
            [name]: value ? value : '',
        });
    }

    // search in phonecode
    const handlefilter = (e) => {

        var str = e.target.value || '';
        setFilterString(str);
        const lowercasedFilter = str.toLowerCase();
        const filteredData = otherInfo.country.filter(item => {
            return item.phone_code.toLowerCase().includes(lowercasedFilter);
        });
        setFilteredPhoneCodes(filteredData)

    }

    // Registration Modal
    const Registration = (props) => {
        const [registartionForm, setRegistartionForm] = useState();
        const [validationError, setValidationError] = useState();
        const [loaded, setLoaded] = useState(true);
        const [hideShow, setHideShow] = useState(true);


        const { addToast } = useToasts();
        const handleChange = (e) => {
            const { name, value: newValue, type } = e.target;
            const value = type === 'number' ? +newValue : newValue;
            setRegistartionForm({
                ...registartionForm,
                [name]: value,
            });
        }
        const handleSubmit = (e) => {
            e.preventDefault();

            var newRegistration = {
                // first_name: registartionForm?registartionForm.firstName:'',
                // last_name: registartionForm?registartionForm.lastName:'',
                email: registartionForm ? registartionForm.email : '',
                username: registartionForm ? registartionForm.UserName : '',
                password: registartionForm ? registartionForm.password : '',
                referral_code: props.referralCode ? props.referralCode : '',
                accept_terms: 1,
            }
            setLoaded(false)
            registrationServices
                .register(newRegistration)
                .then(response => {
                    setLoaded(true)
                    newRegistration = {};
                    setRegistartionForm();
                    props.onSuccessHide();
                    navigate({
                        pathname: '/verify-account',
                        data: response.data
                    })
                })
                .catch(error => {
                    setLoaded(true);
                    if (error.response.status === 422) {
                        setValidationError(
                            error.response.data.errors
                        )
                    }
                    else {
                        addToast(error.response.data.message, { appearance: 'error' })
                        swalWithBootstrapButtons.fire(
                            t('Error'),
                            error.response.data.message,
                            'error'
                        )
                    }
                })
        }
        const hasErrorFor = (field) => {
            if (validationError) {
                const error = validationError.find(element => String(element.field) === String(field));
                if (error)
                    return true;
            } else {
                return false;
            }
        }
        const renderErrorFor = (field) => {
            if (hasErrorFor(field)) {
                const error = validationError.find(element => String(element.field) === String(field));
                return (
                    <div className="error-wrapper">
                        <span className='invalid-feedback'>
                            <strong>{error.message}</strong>
                        </span>
                    </div>
                )
            }
        }


        return (
            <>
                <PageLoader isLoaded={loaded} />
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Modal show={showRegistrationModal} onHide={() => setShowRegistrationModal(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="user-login-modal">
                                <Modal.Header closeButton className="m-0">
                                </Modal.Header>
                                <div className="login-modal">
                                    <Image src="/images/slider-image/opening_up_the_border.webp" alt="image" className="blog-image img-fluid" />
                                    <Modal.Body>
                                        <div className="modal-body-heading">
                                            <h3 className="mt-3">{t("Join Miltrade")}</h3>
                                            <p>{t("Start selling and growing your military collection today by joining our international platform. Sign up today, it's free!")} </p>
                                        </div>
                                        <div className="login-form">
                                            <div className="login-reg-button">
                                                <span onClick={() => { setShowRegistrationModal(false); setShowLoginModal(true); }} className="login-btn cp">Sign in</span>
                                                <span className="reg-btn cp active">{t("Register")}</span>
                                            </div>
                                            <Form onSubmit={handleSubmit} >
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-name"></span>
                                                        <input placeholder=" "
                                                            name="UserName"
                                                            id="username"
                                                            autoComplete="off"
                                                            className={`${hasErrorFor('username') ? 'is-invalid' : ''}`}
                                                            value={registartionForm ? registartionForm.username : ""}
                                                            onChange={handleChange} type="text" />
                                                        <label>{t("Enter your Username")}</label>
                                                    </div>
                                                    {renderErrorFor('username')}
                                                </div>
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-email"></span>
                                                        <input placeholder=" "
                                                            name="email"
                                                            id="email"
                                                            autoComplete="off"
                                                            className={`${hasErrorFor('email') ? 'is-invalid' : ''}`}
                                                            value={registartionForm ? registartionForm.email : ""}
                                                            onChange={handleChange} type="text" />
                                                        <label>{t("Enter your Email address")}</label>
                                                    </div>
                                                    {renderErrorFor('email')}
                                                </div>
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-password"></span>
                                                        <input placeholder=" "
                                                            id="password"
                                                            type={hideShow ? 'password' : 'text'}
                                                            name="password"
                                                            autoComplete="off"
                                                            className={`${hasErrorFor('password') ? 'is-invalid' : ''}`}
                                                            value={registartionForm ? registartionForm.password : ""}
                                                            onChange={handleChange} />
                                                        <label >{t("Enter your password")}</label>
                                                        <div onClick={() => setHideShow(!hideShow)} className="eye-icon cp"><i className={hideShow ? 'icon-eye field-icon' : 'icon-eye_hide field-icon'}></i></div>
                                                    </div>
                                                    {renderErrorFor('password')}
                                                </div>
                                                <div className="large-button mt-3"><Button disabled={!loaded} variant="primary" type="submit"  > Sign Up</Button></div>
                                            </Form>
                                            <div className="social-login">
                                                {/* <p>Or sign up with</p> */}
                                                <SocialLogins onSuccess={handleSocialLogin} onFailure={handleSocialLoginFailure} />
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </div>
                                <div className="footer-modal">
                                    <p>{t("By Creating an account, you agree to our")}
                                        <a target="_blank" href="/support?page=terms-of-service&title=Terms of service&topic=terms-and-conditions&ttitle=Terms and Conditions"> {t("User Agreement")}</a> {t("and acknowledge reading our")}
                                        <a target="_blank" href="/support?page=terms-of-service&title=Terms of service&topic=privacy-policy-1&ttitle=Privacy Policy"> {t("User Privacy Notice")}.</a>
                                    </p>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    //forgot password modal
    const ForgotPassword = (props) => {

        const [forgotPasswordForm, setForgotPasswordForm] = useState({
            email: '',
        });
        const setShowLoginModal = props.setShowLoginModal;
        const [validationError, setValidationError] = useState();
        const [loaded, setLoaded] = useState(true);
        const handleChange = (e) => {
            const { name, value: newValue, type } = e.target;
            const value = type === 'number' ? +newValue : newValue;
            setForgotPasswordForm({
                ...forgotPasswordForm,
                [name]: value,
            });
        }
        const handleSubmit = (e) => {
            e.preventDefault();
            var ForgotPassword = {
                email: forgotPasswordForm ? forgotPasswordForm.email : '',
            }
            setLoaded(false)
            registrationServices
                .forgotPassword(ForgotPassword)
                .then(response => {
                    ForgotPassword = {};
                    setForgotPasswordForm();
                    setLoaded(true)
                    props.onSuccessHide();
                    // addToast("Reset Password link has been sent to email", { appearance: 'success' })
                    swalWithBootstrapButtons.fire(
                        'Link Sent!',
                        'Reset Password link has been sent to email',
                        'success'
                    )

                })
                .catch(error => {
                    setLoaded(true);
                    if (error.response) {
                        if (error.response.status === 422) {
                            setValidationError(
                                error.response.data.errors
                            )
                        }
                        else {
                            swalWithBootstrapButtons.fire(
                                t('Error'),
                                error.response.data.message,
                                'error'
                            )
                        }

                    }
                })


        }
        const hasErrorFor = (field) => {
            if (validationError) {

                const error = validationError.find(element => String(element.field) === String(field));
                if (error)
                    return true;
            } else {
                return false;
            }
        }
        const renderErrorFor = (field) => {
            if (hasErrorFor(field)) {
                const error = validationError.find(element => String(element.field) === String(field));
                return (
                    <div className="error-wrapper">
                        <span className='invalid-feedback'>
                            <strong>{error.message}</strong>
                        </span>
                    </div>
                )
            }
        }


        return (
            <>
                <PageLoader isLoaded={loaded} />
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Modal show={forgotPassword} onHide={() => setForgotPassword(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="user-login-modal">
                                <Modal.Header closeButton className="m-0">
                                </Modal.Header>
                                <div className="login-modal">
                                    <Modal.Body>
                                        <div className="modal-body-heading">
                                            <h3 className="mt-3">{t("Forgot  Password")}</h3>
                                            <p>{t("We will send you a confirmation email, so enter your correct email address.")}</p>
                                        </div>
                                        <div className="login-form">
                                            <Form onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-email"></span>
                                                        <input placeholder=" "
                                                            name="email"
                                                            className={`${hasErrorFor('email') ? 'is-invalid' : ''}`}
                                                            id="email"
                                                            autoComplete="off"
                                                            onChange={handleChange}
                                                            value={forgotPasswordForm ? forgotPasswordForm.email : ""}
                                                            type="email" />
                                                        <label>{t("Enter your Email address")}</label>
                                                    </div>
                                                    {renderErrorFor('email')}
                                                </div>
                                                <div className="large-button mt-5"><Button variant="primary" type="submit"> {t("Send")}</Button></div>
                                            </Form>
                                            <div className="social-login">
                                                <h5 className="mt-4">{t("Remember Password")} ? <a onClick={() => { setForgotPassword(false); setShowLoginModal(true); }} href="#">{t("Sign In")}</a></h5>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    // Socail Login Email data 
    const UserEmailChange = (e) => {
        const { name, value: newValue, type } = e.target;
        const value = type === 'number' ? +newValue : newValue;
        setSocialoginData({
            ...socialoginData,
            [name]: value,
        });
    }

    // provide email to sever after social login if not provided  
    const handleUserEmailSubmit = (e) => {
        e.preventDefault()
        SocialLogin(socialoginData)
    }

    // reset password modal 
    const ResetPassword = (props) => {
        const [resetPasswordForm, setResetPasswordForm] = useState({
            password: '',
            passwordConfirmation: '',
        });
        const [hideShow, setHideShow] = useState(false);
        const [validationError, setValidationError] = useState();
        const [loaded, setLoaded] = useState(true);
        const { addToast } = useToasts();
        const handleChange = (e) => {
            const { name, value: newValue, type } = e.target;
            const value = type === 'number' ? +newValue : newValue;
            setResetPasswordForm({
                ...resetPasswordForm,
                [name]: value,
            });
        }
        const handleSubmit = (e) => {
            e.preventDefault();

            var resetPass = {
                token: resetToken,
                email: resetEmail,
                password: resetPasswordForm ? resetPasswordForm.password : '',
                password_confirmation: resetPasswordForm ? resetPasswordForm.passwordConfirmation : '',
            }
            setLoaded(false)
            registrationServices
                .resetPassword(resetPass)
                .then(response => {
                    resetPass = {};
                    setResetPasswordForm();
                    setLoaded(true)
                    addToast(response.msg, { appearance: 'success' })
                    props.onSuccessHide();
                    navigate('/')
                })
                .catch(error => {
                    setLoaded(true);
                    if (error.response) {
                        if (error.response.status === 422) {
                            setValidationError(
                                error.response.data.errors
                            )
                        }
                        else {
                            addToast(`${error.response.data.msg} ${t("Please check your email link again")} `, { appearance: 'error' })
                            props.onSuccessHide();
                            navigate('/')
                        }
                    }
                })


        }
        const hasErrorFor = (field) => {
            if (validationError) {
                const error = validationError.find(element => String(element.field) === String(field));
                if (error)
                    return true;
            } else {
                return false;
            }
        }
        const renderErrorFor = (field) => {
            if (hasErrorFor(field)) {
                const error = validationError.find(element => String(element.field) === String(field));
                return (
                    <div className="error-wrapper">
                        <span className='invalid-feedback'>
                            <strong>{error.message}</strong>
                        </span>
                    </div>
                )
            }
        }

        return (
            <>
                <PageLoader isLoaded={loaded} />
                <Container>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Modal show={resetPasswordModal} onHide={() => setResetPasswordModal(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
                                <div className="login-modal">
                                    <Modal.Body>
                                        <div className="modal-body-heading">
                                            <h3 className="mt-3">{t("Reset  Password")}</h3>
                                            <p>{t("Set your new password below")}.</p>
                                        </div>
                                        <div className="login-form">
                                            <Form >
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-password"></span>
                                                        <input placeholder=" "
                                                            id="password"
                                                            type={hideShow ? 'text' : 'password'}
                                                            name="password"
                                                            autoComplete="off"
                                                            className={`${hasErrorFor('password') ? 'is-invalid' : ''}`}
                                                            value={resetPasswordForm ? resetPasswordForm.password : ""}
                                                            onChange={handleChange} />
                                                        <label >{t("Enter your password")}</label>
                                                        <div onClick={() => setHideShow(!hideShow)} className="eye-icon cp"><i className={hideShow ? 'icon-eye_hide field-icon' : 'icon-eye field-icon'}></i></div>
                                                    </div>
                                                    {renderErrorFor('password')}
                                                </div>
                                                <div className="form-group">
                                                    <div className="material-textfield mt-4">
                                                        <span className="icon-password"></span>
                                                        <input placeholder=" "
                                                            name="passwordConfirmation"
                                                            className={`${hasErrorFor('password_confirmation') ? 'is-invalid' : ''}`}
                                                            id="password_confirmation"
                                                            onChange={handleChange}
                                                            autoComplete="off"
                                                            value={resetPasswordForm ? resetPasswordForm.passwordConfirmation : ""}
                                                            type="password" />
                                                        <label>{t("Confirm your password")}</label>
                                                    </div>
                                                    {renderErrorFor('password_confirmation')}
                                                </div>
                                                <div className="large-button mt-5"><Button variant="primary" onClick={handleSubmit}> {t("Send")}</Button></div>
                                            </Form>
                                            <div className="social-login">
                                                <h5 className="mt-4">{t("Remember Password")} ? <a onClick={() => { setResetPasswordModal(false); setShowLoginModal(true); }} href="#">{t("Sign In")}</a></h5>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    // country dropdown handler
    const handleCountrySelect = (e) => {
        const pcode = e.currentTarget.dataset.value;
        const pflag = e.currentTarget.dataset.image;
        const pname = e.currentTarget.dataset.name;
        setCountryFlag({
            ...countryFlag,
            country_code: pcode,
            name: pname,
            flag: pflag
        })

        setFormData({
            ...formData,
            country_code: pcode,
        });
        let tempData = otherInfo.country.find(p => String(p.country_code) === String(pcode));
        setSelectedPhoneOption({
            ...tempData,
            phoneCode: tempData.phone_code
        });
        getCities(pcode, null)
    }

    // country dropdown serach
    const handleCountryfilter = (e) => {

        var str = e.target.value || '';
        setFilterCountrytString(str);
        const lowercasedFilter = str.toLowerCase();
        const filteredData = otherInfo.country.filter(item => {
            return item.name.toLowerCase().includes(lowercasedFilter);
        });
        // filteredPhoneCodes,setFilteredPhoneCodes
        setFilteredCountries(filteredData)

    }

    // City dropdown handler
    const handleCityChange = (e) => {
        const value = e.currentTarget.dataset.value;
        const find = cities.find(c => String(c.id) === String(value));
        if (typeof (find) != 'undefined') {
            setCity(find);
        }
    }

    // City dropdown serach
    const handleCityfilter = (e) => {

        var str = e.target.value || '';
        setFilterCityString(str);
        const lowercasedFilter = str.toLowerCase();
        const filteredData = cities.filter(item => {
            return item.name.toLowerCase().includes(lowercasedFilter);
        });
        setFilterCities(filteredData)

    }

    const handleCurrency = (e) => {
        setCurrency({
            ...currency,
            code: e.currentTarget.dataset.code,
            symbol: e.currentTarget.dataset.symbol
        })

        // default_currency
        setFormData({
            ...formData,
            default_currency: e.currentTarget.dataset.code,
        });

    }

    // Form error handler
    const hasErrorFor = (field) => {
        if (validationError) {

            const error = validationError.find(element => String(element.field) === String(field));
            if (error)
                return true;
        } else {
            return false;
        }
    }

    const renderErrorFor = (field) => {
        if (hasErrorFor(field)) {
            const error = validationError.find(element => String(element.field) === String(field));
            return (
                <div className="error-wrapper">
                    <span className='invalid-feedback'>
                        <strong>{error.message}</strong>
                    </span>
                </div>
            )
        }
    }

    // T&C handler
    const handleChecked = () => {
        setConsentTC(!consentTC);
    }



    return (
        <div>



            <Container>
                <Login
                    onSuccessHide={() => setShowLoginModal(false)}
                    setShowRegistrationModal={setShowRegistrationModal}
                    onSuccessModal={onSuccessModal}
                    handleSocialLogin={handleSocialLogin}
                    handleSocialLoginFailure={handleSocialLoginFailure}
                    loginUserData={HandleLoggedUser}
                />
                <Registration referralCode={referralCode} onSuccessHide={() => setShowRegistrationModal(false)} />
                <ForgotPassword onSuccessHide={() => setForgotPassword(false)} setShowLoginModal={setShowLoginModal} />
                <ResetPassword onSuccessHide={() => setResetPasswordModal(false)} setShowLoginModal={setShowLoginModal} />
            </Container>
            <PageLoader isLoaded={loaded} />
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Modal dialogClassName="modal-90w" show={lgShow} aria-labelledby="example-modal-sizes-title-lg" className="user-login-modal ">
                            <Modal.Header className="">
                            </Modal.Header>
                            <div className="defalut-modal">
                                <Modal.Body className="px-5">
                                    <div className="modal-body-heading">
                                        <h1 className="mt-3">{t("Welcome to Miltrade")}!</h1>
                                        <p className="text">{t("Thank you for creating a Miltrade account! Please complete the fields below to complete your account registration process. By providing this information you confirm that you are a real person and we will immediately set your user preferences for Miltrade")}.</p>
                                    </div>
                                    <div className="">
                                        <Form onSubmit={updateUserProfile}>
                                            {(tempUserData?.username === "" || tempUserData?.username == null) && (
                                                <div className="form-group user-info1">
                                                    <label className="reg-info-text-label" for="exampleInputEmail1">{t("Username")} <span className="info-box-val">*</span></label>
                                                    {/* <div className="icon-box1"><i className="icon-name"></i></div> */}
                                                    <input className="form-control"
                                                        name="username"
                                                        autoComplete="off"
                                                        value={formData?.username}
                                                        onChange={handleOtherFromChange}
                                                        placeholder={t("Enter your Username")} />
                                                    {renderErrorFor('username')}
                                                </div>
                                            )}
                                            <div className="form-group user-info1">
                                                <label className="reg-info-text-label" for="exampleInputEmail1">{t("First name")} <span className="info-box-val">*</span></label>
                                                <small className="reg-info-text form-text text-muted"> {t("Your name will only be used for account confirmation and will not be visible to other Mitrade users")}</small>
                                                <div className="icon-box1"><i className="icon-name"></i></div>
                                                <input className="form-control"
                                                    name="first_name"
                                                    autoComplete="off"
                                                    value={formData?.first_name}
                                                    onChange={handleOtherFromChange}
                                                    placeholder={t("Enter your first name")} />
                                                {renderErrorFor('first_name')}
                                            </div>
                                            <div className="form-group user-info1">
                                                <label className="reg-info-text-label" for="exampleInputEmail1">{t("Last name")} <span className="info-box-val">*</span></label>
                                                <div className="icon-box"><i className="icon-name"></i></div>
                                                <input className="form-control"
                                                    name="last_name"
                                                    autoComplete="off"
                                                    value={formData?.last_name}
                                                    onChange={handleOtherFromChange}
                                                    placeholder="Enter your last name" />
                                                {renderErrorFor('last_name')}
                                            </div>
                                            <Form.Group controlId="formGridState">
                                                <label className="reg-info-text-label" for="exampleInputEmail1">{t("Country")} <span className="info-box-val">*</span></label>
                                                <Dropdown className="form-control profileDropDown">
                                                    <div className="subCategoryWrapper">
                                                        <Dropdown.Toggle className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
                                                            <div className={`dropbtn `}>{countryFlag && (
                                                                <>
                                                                    <Image className="flag-image" src={countryFlag?.flag} alt={countryFlag?.flag} />
                                                                    <span> {countryFlag?.name}</span>
                                                                </>
                                                            )}</div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div className="mobile-code-search">
                                                                <div className="form-group">
                                                                    <input value={filterCountrytString} onChange={handleCountryfilter} placeholder={t("search list")} type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            {filteredCountries && filteredCountries?.map((country, i) =>
                                                                <Dropdown.Item onClick={handleCountrySelect}
                                                                    data-image={country?.flag}
                                                                    data-name={country?.name}
                                                                    data-value={country?.country_code}
                                                                    key={i} > <img src={country?.flag} alt={country?.name} /> {country?.name}
                                                                </Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                                {renderErrorFor('country_code')}
                                            </Form.Group>
                                            <Form.Group controlId="formGridtext">
                                                <div className="user-info1">
                                                    <label className="reg-info-text-label" for="exampleInputEmail1">{t("City")} <span className="info-box-val">*</span></label>
                                                    <div className="icon-box"><i className="icon-city"></i></div>
                                                    <Dropdown className="form-control profileDropDown">
                                                        <div className="subCategoryWrapper">
                                                            <Dropdown.Toggle className="mobile-number-left-dropdown city-left-dropdown" variant="success" id="dropdown-basic">
                                                                <div className={`dropbtn `}>{city && (
                                                                    <><span> {city?.name}</span> </>
                                                                )}</div>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <div className="mobile-code-search">
                                                                    <div className="form-group">
                                                                        <input value={filterCityString} onChange={handleCityfilter} placeholder="search city" type="text" className="form-control" />
                                                                    </div>
                                                                </div>
                                                                {filterCities?.map((city, i) =>
                                                                    <Dropdown.Item onClick={handleCityChange}
                                                                        data-name={city?.name}
                                                                        data-value={city.id}
                                                                        key={i} > {city?.name}
                                                                    </Dropdown.Item>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </div>
                                                    </Dropdown>
                                                    {renderErrorFor('city_id')}
                                                </div>
                                            </Form.Group>


                                            <div className="form-group">
                                                <label className="reg-info-text-label" for="exampleInputEmail1">{t("Phone number")} <span className="info-box-val">*</span></label>
                                                <small className="reg-info-text form-text text-muted"> {t("Your phone number will only be used for account confirmation")}.</small>
                                                <div className="input-group mb-3">
                                                    <Dropdown>
                                                        <div className="subCategoryWrapper">
                                                            <Dropdown.Toggle disabled={true} className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
                                                                <div className={`dropbtn `}>{selectedPhoneOption && (
                                                                    <>
                                                                        <Image className="rounded" src={selectedPhoneOption?.flag} alt={selectedPhoneOption?.flag} />
                                                                        <span> {selectedPhoneOption?.phoneCode}</span>
                                                                    </>
                                                                )}</div>

                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <div className="mobile-code-search">
                                                                    <form className="">
                                                                        <div className="form-group">
                                                                            <input value={filterString} onChange={handlefilter} autoComplete="off" placeholder={t("search list")} type="text" id="formBasic" className="form-control" />
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                {filteredPhoneCodes?.map((code, i) =>
                                                                    <Dropdown.Item onClick={handlePhoneCodeSelect}
                                                                        data-image={code.flag}
                                                                        data-value={code?.phone_code}
                                                                        key={i} > <img src={code?.flag} alt={code?.phone_code} /> {code?.phone_code}
                                                                    </Dropdown.Item>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </div>
                                                    </Dropdown>
                                                    <Form.Control
                                                        name="phone"
                                                        autoComplete="off"
                                                        value={formData?.phone}
                                                        min="0"
                                                        onChange={handleOtherFromChange}
                                                        placeholder={t("Enter your phone number")}
                                                    />
                                                </div>
                                                {renderErrorFor('phone') || renderErrorFor('phone_code')}

                                            </div>
                                            <div className="form-group user-info1">
                                                <label className="reg-info-text-label" for="exampleInputEmail1">{t("Currency")} <span className="info-box-val">*</span></label>
                                                <Dropdown className="form-control profileDropDown">
                                                    <div className="subCategoryWrapper">
                                                        <Dropdown.Toggle className="mobile-number-left-dropdown" variant="success" id="dropdown-basic">
                                                            <div className={`dropbtn `}>{currency?.code ? (

                                                                <p>{currency?.symbol}
                                                                    <span> {currency?.code}</span></p>

                                                            ) : (<p>{t("Select Default Currency")}</p>)}</div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <div className="mobile-code-search">
                                                                <div className="form-group">
                                                                </div>
                                                            </div>
                                                            {otherInfo && otherInfo?.currencies?.map((country, i) => (
                                                                <Dropdown.Item onClick={handleCurrency}

                                                                    data-symbol={country?.symbol}
                                                                    data-code={country?.code}
                                                                    key={i} > {country?.symbol} {country?.code}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </div>
                                                </Dropdown>
                                                {renderErrorFor('default_currency')}
                                            </div>

                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={handleChecked} />
                                                <p className="checkbox-reg-info-text"> {t("I have read the")} <Link target="_blank" to="/support?page=catalogue-rules&title=Catalogue rules"> {t("terms & conditions")} </Link> {t("and")} <Link target="_blank" to="/support?page=terms-of-service&title=Terms of service&topic=privacy-policy-1&ttitle=Privacy Policy">{t("privacy policy")}</Link>  {t("and I confirm that the information I provided is correct")}.</p>
                                                {/* <label className="form-check-label" for="exampleCheck1">Check me out</label> */}
                                            </div>
                                            <div className="large-button save-setting-btn"><Button variant="primary" disabled={!consentTC} type="submit"> {t("Save settings & Continue to Miltrade")}</Button></div>
                                        </Form>
                                    </div>
                                </Modal.Body>
                            </div>
                        </Modal>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Modal show={userPassordModal} onHide={() => setUserPassordModal(false)} dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title" className="user-login-modal">
                            <Modal.Header closeButton className="m-0">
                            </Modal.Header>
                            <div className="login-modal">
                                <Modal.Body>
                                    <div className="modal-body-heading">
                                        <h3 className="mt-3">{t("Provide Email")}</h3>
                                        <p className="reg-info-text"> {t("Email is required as not provided in social login")}.</p>
                                    </div>
                                    <div className="login-form">
                                        <Form onSubmit={handleUserEmailSubmit}>
                                            <div className="form-group">
                                                <div className="material-textfield mt-4">
                                                    <span className="icon-email"></span>
                                                    <input placeholder=" "
                                                        name="email"
                                                        id="email"
                                                        autoComplete="off"
                                                        className={`${socialError ? 'is-invalid' : ''}`}
                                                        onChange={UserEmailChange}
                                                        value={socialoginData?.email}
                                                        type="email" />
                                                    <label>{t("Enter your Email address")}</label>
                                                </div>
                                                {socialError && (
                                                    <div className="error-wrapper">
                                                        <span className='invalid-feedback'>
                                                            <strong>{socialError}</strong>
                                                        </span>
                                                    </div>
                                                )}

                                            </div>
                                            <div className="large-button mt-5"><Button variant="primary" type="submit"> {t("Ok")}</Button></div>
                                        </Form>

                                    </div>
                                </Modal.Body>
                            </div>
                        </Modal>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Authentication;