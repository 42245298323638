import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';

import { Link } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Encriptor from '../../../services/encriptor';
import axios from 'axios';
import defualtImage from './product.jpg'
import { useTranslation } from 'react-i18next';



const GirdView = ({ posts, LikeUnlikeToggleButton }) => {
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   const CheckImage = (path) => {
      axios
         .get(path)
         .then(() => {
            return true;
         })
         .catch(() => {
            return false;
         });
   }
   const { t } = useTranslation();
   if (posts) {
      return (
         <>
            <div className="grid-post">

               <Row className="no-gutters-row-only">
                  {posts?.data?.map((post, i) => (
                     <Col xs={6} key={i} sm={6} md={4} lg={3} className="all-post-dispaly">
                        <div className="product-display mt-3">
                           <div className="card">
                              {post?.featured && (<div className="featured"><span>{t("FEATURED")}</span></div>)}
                              {loginUser && parseInt(post?.user_id) !== parseInt(loginUser?.id) && (
                                 <div className="heart cp">
                                    <span>
                                       {post.likedbyme ? (
                                          <div className="red_favourite" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-red_favourite active" aria-hidden="true"></i></div>
                                       ) : (
                                          <div className="favourite1" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-favourite" aria-hidden="true"></i></div>
                                       )}
                                    </span>
                                 </div>
                              )}
                              <div className="img-card">
                                 <div className="views-sect"><span><Link ><i className="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)}</Link> </span></div>
                                 <Link to={`/product/detail/${post.slug}`} >
                                    <Image src={ CheckImage(post?.featured_image?.sm) ?  post?.featured_image?.sm : defualtImage} alt={post?.slug} className="img-fluid" />
                                 </Link>
                              </div>
                              <div className="cards">
                                 <h5 className="Product-title">
                                    <Link to={`/product/detail/${post?.slug}`} >
                                       <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                             <Tooltip id={`tooltip-${post?.id}`}>
                                                <span> {`${post?.title}`}</span>
                                             </Tooltip>
                                          }
                                       >
                                          {(post?.title?.length) > 36 ? (

                                             <div className="">

                                                <span>{`${post?.title}`.slice(0, 36)} ... </span>

                                             </div>

                                          ) : (
                                             <div className="">
                                                <span>{`${post?.title}`}</span>
                                             </div>
                                          )}
                                       </OverlayTrigger>
                                    </Link>
                                 </h5>
                                 <div className="Product-desc">
                                    <OverlayTrigger
                                       placement="bottom"
                                       delay={{ show: 250, hide: 400 }}
                                       overlay={
                                          <Tooltip className="large-tooltip" id={`tooltip-${post?.id}`}>
                                             <span>{`${post?.short_description}`}</span>
                                          </Tooltip>
                                       }
                                    >
                                       {(String(post?.short_description).length) > 50 ? (

                                          <div className="">
                                             <span>{`${post?.short_description}`.slice(0, 50)} ...</span>
                                          </div>

                                       ) : (
                                          <div className="">
                                             <span> {`${post?.short_description}`}</span>
                                          </div>
                                       )}
                                    </OverlayTrigger>
                                 </div>
                                 <div className="price-location">
                                    <ul className='d-flex'>
                                       <li className=""><strong>{post?.open_to_offer ? (`${t('Open to offers')}`) : (post?.formatted_price)}</strong></li>
                                       <li className="location">
                                          <OverlayTrigger
                                             placement="bottom"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={
                                                <Tooltip id={`tooltip-${post?.id}`}>
                                                   <span>

                                                      {`${post?.address} `}
                                                   </span>
                                                </Tooltip>
                                             }
                                          >
                                             <div className="country_location g-1">
                                                <img alt={post?.country_flag} src={post?.country_flag} />
                                                <span> {(post?.address.length) > 10 ? `${String(post?.address).slice(0, 10)} ...` : `${post?.address}`} </span>
                                             </div>

                                          </OverlayTrigger>

                                       </li>
                                    </ul>
                                 </div>
                                 <div className="post-owner-details">
                                    <Row>
                                       <Col xs={4} sm={4} md={3} lg={3} className="p-0 pl-1">

                                          <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                             <div className='item-seller'>
                                                {post?.user?.issubscribed && (
                                                   <div className="verified-dealer">
                                                      <Image src="../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
                                                   </div>
                                                )}

                                                <div className={`seller-image-${post?.user?.issubscribed ? 'verified' : 'unverified'}`}>
                                                   <Image src={post?.user?.photo} alt="1" className="img-fluid" />
                                                </div>
                                             </div>
                                          </Link>
                                       </Col>
                                       <Col xs={8} sm={8} md={9} lg={5} className="p-0">
                                          <div className={`seller-desc ${(loginUser && parseInt(post?.user_id) !== parseInt(loginUser?.id)) ? '' : 'seller-desc1'}`}>
                                             <h3>
                                                <div className="">
                                                   <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                                      <OverlayTrigger
                                                         placement="bottom"
                                                         delay={{ show: 250, hide: 400 }}
                                                         overlay={
                                                            <Tooltip id={`tooltip-${post?.user?.id}`}>
                                                               <span>
                                                                  {`${post?.user?.username} `}

                                                               </span>
                                                            </Tooltip>
                                                         }
                                                      >
                                                         {(post?.user?.username.length) > 8 ? (

                                                            <div className="">
                                                               <span>
                                                                  {`${post?.user?.username}`.slice(0, 8)} ...
                                                               </span>
                                                            </div>

                                                         ) : (
                                                            <div className="">
                                                               <span>
                                                                  {`${post?.user?.username}`}
                                                               </span>
                                                            </div>
                                                         )}
                                                      </OverlayTrigger>
                                                   </Link>
                                                </div>
                                             </h3>
                                             {post?.user?.issubscribed ? (<h6>{t("Verified Seller")}</h6>) : (<h6 className="private-seller">{t("Private Seller")}</h6>)}
                                          </div>
                                       </Col>
                                       <Col xs={12} sm={12} md={12} lg={4} className="pl-1 pr-2">
                                          <div className="view">
                                             <Link ><i className="icon-star_fill" aria-hidden="true"></i> <span> {parseFloat(post?.user?.averagerating).toFixed(1)}</span></Link>
                                             ({Encriptor.kformatter(post?.user?.review_count)})
                                             <div className="profile-visit mt-1"><Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`}>{t("Visit Profile")}</Link></div>
                                          </div>
                                       </Col>
                                    </Row>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </Col>
                  ))}

               </Row>

            </div>
         </>
      )
   }
   return (
      <>
         {t("No listing found")}...
      </>
   )
}
export default GirdView;