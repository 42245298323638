import React,{useState, useContext} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link,useLocation,useNavigate } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Header from "../include/header";
import Footer from "../include/footer";
import registrationServices from '../../services/registration'
import { useToasts } from 'react-toast-notifications';
import PageLoader from '../pageLoader';
import SeoTags from '../seo/seoTags'
import { Auth } from '../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';


var isHomePage =  false;
const VerifyAccount = () => {
   const { setShowLoginModal } = useContext(Auth);
   let location = useLocation();
   const navigate = useNavigate();
   const [isLoaded,setIsLoaded] =  useState(true);
   const { data } = location;
   const { addToast } = useToasts();
   if(!data){
      navigate('/');
   }
   const { t } = useTranslation();
   // handle send again verify link 
   const sendVerificationLinkAgain = (email)=>{
      registrationServices.reverifyEmail({email:email})
      .then(response => {
         setIsLoaded(true);
         if(response.success){
            addToast(`${t("A verification link has been sent again")} ..`,{ appearance: 'success' })
         }else
         {
           
            addToast(response.message,{ appearance: 'error' })
         }
         
      })
      .catch(error => {
      
         setIsLoaded(true);
         if(error.response){
            addToast(error.response.data.message, { appearance: 'error' })
         }
      })
   }
      
   return (
      <>
         <SeoTags title={`Miltrade | Verify account`} 
            description={''} 
            category={''} 
            url={window.location.href} 
            image={''} 
         />
         <Header 
               data={isHomePage}
               checkAuth={false}
               
            />
            <PageLoader isLoaded={isLoaded} />
            <div className="footer-bottom1">
            <div className="breadcrum">
               <Container fluid>
                  <Row>
                     <Col>
                     <Breadcrumb>
                        	<Breadcrumb.Item  linkProps={{ to: '/' }} linkAs={Link} >{t("Home")}</Breadcrumb.Item>
                        <Breadcrumb.Item active>{t("Verify Account")}</Breadcrumb.Item>
                     </Breadcrumb>
                     </Col>
                  </Row>
               </Container>
            </div>
            <div className="verify_account_section">
               <Container>
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={12}>
                        <div>
                           <Link to="/"><Image src="/images/logo/miltrade_logo.svg" width='150' alt="flag" className="img-fluid" /></Link> 
                        </div>
                     </Col>     
                  </Row>
               </Container>
            </div>
            <div className="smile-image-section">
               <Container>
                     <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                           <div>
                              <Link to="/"><Image src="/images/smile.png"  alt="smile" className="img-fluid" /></Link> 
                           </div>
                        </Col>     
                     </Row>
                  </Container>   
            </div>
            <div className="verify-email-section pb-5">
            <Container>
                  <Row>
                     <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="check-email">
                        <p>
                        {t("We have sent you an email to verify your email address.  Please check your email")} <Link mailto={data?.email} > {data?.email}</Link> <br/> {t("and click the link in our email to verify your account. Didn't receive an email?  Please wait a few minutes and check")}<br/> {t("your spam folder")}.
{t("Still no email")}?  <span className="cp" onClick={()=>sendVerificationLinkAgain(data?.email)} > <strong> <span className="click-here">{t("Click here")} </span>{t("to resend confirmation email")} </strong></span>
                        </p>
                        </div>
                        <div className="check-email2">
                        <h5>
                           <Link mailto={data?.email} > {data?.email}</Link> {t("not your email address?")} <span><Link mailto="" to="/"> </Link> <Link onClick={()=>setShowLoginModal(true)} >{t("Sign up")} </Link></span> <br/>
                           {t("with your correct email address. Already have an account?")} <span><Link onClick={()=>setShowLoginModal(true)} >{t("Click here to log in")}</Link></span>
                        </h5>
                        <h6>{t("Need help with creating and/or verifying your account? Please")} <Link target="_blank" to={'/support?page=support-contact-1&title=Support&topic=contact-miltrade-1&ttitle=Contact Miltrade'} >{t("contact us")}</Link> {t("and we will be happy to assist")}.</h6>
                        </div>
                     </Col>     
                  </Row>
               </Container>   
         </div>
         </div>
         <Footer />
      </>
   );
}
export default VerifyAccount;