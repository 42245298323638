import React, { useContext } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import Encriptor from '../../../services/encriptor'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// import defualtImage from './productDefault.svg'
// import axios from 'axios';
import { Auth } from '../../../Contexts/AuthContext';
import { useTranslation } from 'react-i18next';


const ListView = ({ posts, LikeUnlikeToggleButton }) => {
   const { setShowLoginModal } = useContext(Auth);
   const loginUser = window.localStorage.getItem('loggedUser') ? JSON.parse(window.localStorage.getItem('loggedUser')) : false;
   const { t } = useTranslation();
   if (posts) {
      return (
         <>
            <div className="list-post-sections-views">
               <div className="list-post-sections-views">
                  {posts?.data?.map((post, i) => (

                     <Col xs={12} sm={12} md={12} lg={12}>
                        <div className="product-list-post mt-3">
                           <Row className="no-gutters">
                              <Col xs={12} sm={12} md={3} lg={3}>
                                 <div className="post-list-image">
                                    {post?.featured && (<div className="featured"><span>{t("FEATURED")}</span></div>)}
                                    {loginUser ? (String(post?.user_id) !== String(loginUser?.id) && (
                                       <div className="heart cp">
                                          <span>
                                             {post.likedbyme ? (
                                                <div className="red_favourite" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-red_favourite active" aria-hidden="true"></i></div>
                                             ) : (
                                                <div className="favourite1" onClick={() => LikeUnlikeToggleButton(post.postid)} > <i className="icon-favourite" aria-hidden="true"></i></div>
                                             )}
                                          </span>
                                       </div>
                                    )) : (<div className="heart cp">
                                       <span><div className="favourite1" onClick={() => setShowLoginModal(true)} > <i className="icon-favourite" aria-hidden="true"></i></div> </span>
                                    </div>)}
                                    <div className="list-post-image">
                                       <div class="views-sect"><span><Link ><i class="icon-eye" aria-hidden="true"></i> {Encriptor.kformatter(post?.visits)} </Link> </span></div>
                                       <Link to={`/product/detail/${post.slug}`} ><Image src={post?.featured_image?.sm ? post?.featured_image?.sm : "/images/product1.jpg"} alt={post?.slug} className="img-fluid" /> </Link>
                                    </div>
                                 </div>
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={7}>
                                 <div className="post-list-desc">
                                    <h5 className="Product-title">
                                       <Link to={`/product/detail/${post?.slug}`} >
                                          <OverlayTrigger
                                             placement="bottom"
                                             delay={{ show: 250, hide: 400 }}
                                             overlay={
                                                <Tooltip id={`tooltip-${post?.id}`}>
                                                   {`${post?.title}`}
                                                </Tooltip>
                                             }
                                          >
                                             {(post?.title?.length) > 100 ? (

                                                <div className="">

                                                   {`${post?.title}`.slice(0, 100)} ...

                                                </div>

                                             ) : (
                                                <div className="">
                                                   {`${post?.title}`}
                                                </div>
                                             )}
                                          </OverlayTrigger>
                                       </Link>
                                    </h5>
                                    <p className="Product-desc">
                                       <OverlayTrigger
                                          placement="bottom"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                             <Tooltip className="tooltiptext" id={`tooltip-${post?.id}`}>
                                                {`${post?.short_description} `}
                                             </Tooltip>
                                          }
                                       >
                                          {(post?.short_description.length) > 150 ? (

                                             <div className="">
                                                {`${post?.short_description}`.slice(0, 150)} ...
                                             </div>

                                          ) : (
                                             <div className="">
                                                {`${post?.short_description}`}
                                             </div>
                                          )}
                                       </OverlayTrigger>
                                    </p>
                                    <div className="price-location">
                                       <ul className='d-flex'>
                                          <li className=""><strong>{post?.open_to_offer ? (`${t('Open to offers')}`) : (post?.formatted_price)}</strong></li>
                                          <li className="location">
                                             <div className="country_location g-1">
                                                <img alt={post?.country_flag} src={post?.country_flag} />
                                                <span>{post?.address} </span>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </Col>
                              <Col xs={12} sm={12} md={3} lg={2} className="owner-details">
                                 <div className="post-list-owner">
                                    <div className="owner-detail">
                                       <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                          <div className='item-seller'>
                                             {post?.user?.issubscribed && (
                                                <div className="verified-dealer">
                                                   <Image src="../images/animation/verified_dealer.svg" alt="1" className="img-fluid verified-dealer-imag" />
                                                </div>
                                             )}

                                             <div className={`seller-image-${post?.user?.issubscribed ? 'verified' : 'unverified'}`}>
                                                <Image src={post?.user?.photo} alt="1" className="img-fluid" />
                                             </div>
                                          </div>
                                       </Link>
                                    </div>
                                    <div className="seller-desc">
                                       <h3>
                                          <div className="">
                                             <Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`} >
                                                <OverlayTrigger
                                                   placement="bottom"
                                                   delay={{ show: 250, hide: 400 }}
                                                   overlay={
                                                      <Tooltip id={`tooltip-${post?.user?.id}`}>
                                                         {`${post?.user?.username} `}
                                                      </Tooltip>
                                                   }
                                                >
                                                   {(post?.user?.username.length) > 15 ? (

                                                      <div className="">
                                                         {`${post?.user?.username} `.slice(0, 15)} ...
                                                      </div>

                                                   ) : (
                                                      <div className="">
                                                         {`${post?.user?.username} `}
                                                      </div>
                                                   )}
                                                </OverlayTrigger>
                                             </Link>
                                          </div>
                                       </h3>
                                       {post?.user?.issubscribed ? (<h6>{t("Verified Seller")}</h6>) : (<h6 className="private-seller" >{t("Private Seller")}</h6>)}
                                       {/* {loginUser && post?.user_id != loginUser?.id && (
                                 <div onClick={()=>FollowUnfollowToggle(post.user_id)} className="followbtn">
                                    <span className="cp">{post?.user?.followedbyme ?'Unfollow':'Follow'}</span>
                              </div>
                           )} */}
                                    </div>
                                    <div className="view mb-1">
                                       <Link ><i class="icon-star_fill" aria-hidden="true"></i> <span>{parseFloat(post?.user?.averagerating).toFixed(1)}</span></Link>({Encriptor.kformatter(post?.user?.review_count)})
                                       <div className="profile-visit mt-1"><Link to={`/seller/profile/${Encriptor.encrypt(post?.user?.id)}`}>{t("Visit Profile")}</Link></div>
                                    </div>
                                 </div>
                              </Col>
                           </Row>
                        </div>
                     </Col>

                  ))}
               </div>

            </div>
         </>
      )
   }
   return (
      <>
         {t("No listing found")}...
      </>
   )

}
export default ListView;