import React, { useState } from 'react';
import Container from "react-bootstrap/Container";
import { Link } from 'react-router-dom'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Header, Footer } from "../../index";
import { useLocation, useNavigate } from "react-router-dom";
import SeoTags from '../../seo/seoTags'
import { useTranslation } from 'react-i18next';


var isHomePage = false;

const MrakListedView = () => {
	let location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [completed, setCompleted] = useState(true);
	const { postData } = location;
	if (!postData) {
		navigate('/');
	}

	const goTofetured = (e) => {
		e.preventDefault();
		navigate(`/featured/${postData.slug}`)
	}
	const goToPreview = (e) => {
		e.preventDefault();
		navigate(`/product/detail/${postData.slug}`)
	}
	return (
		<>

			<SeoTags title={`Miltrade | Listing published`}
				description={''}
				category={''}
				url={window.location.href}
				image={''}
			/>

			{/* Start of header section */}
			<Header data={isHomePage} checkAuth={true} />
			{/* end of header section */}
			<div className="footer-bottom1">
				<div className="breadcrum">
					<Container fluid>
						<Row>
							<Col>
								<Breadcrumb>
									<Breadcrumb.Item linkProps={{ to: '/' }} linkAs={Link} >{t('Home')}</Breadcrumb.Item>
									<Breadcrumb.Item active >{completed ? t('Ad placed') : t('place listing')}</Breadcrumb.Item>
								</Breadcrumb>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="featured-tag text-center">
					<Container>

						<Row>
							<Col xs={12} sm={12} md={12} lg={12}>
								<div className="featured_tag-img">
									<Image src="/images/slider-image/featured_tag.webp" className="img-fluid" alt="user" />
								</div>
								<div className="featured_tag-desc mt-3">
									<h3>{t('Reach More Buyers & Sell Faster with Featured Tag')}</h3>
									<p>{t('Upgrade your Ad a top position with featured tag')}</p>
								</div>
								{!postData?.featured && (
									<div className="feature-tag-button1 mt-5">
										<button onClick={goTofetured} className="btn btn-primary">{t('Sell Faster with Featured Tag')}</button>
									</div>
								)}
								<div className="feature-tag-button2 mt-3">
									<button onClick={goToPreview} className="btn btn-primary">{t('Preview Ad')}</button>
								</div>
							</Col>
						</Row>


					</Container>
				</div>

				<Container>
					<Row>
						<Col xs={12} sm={12} md={12} lg={12}>
							<Modal show={completed} onHide={() => setCompleted(false)} dialogClassName="modal-90w animation_congratulations-modal" className="user-login-modal">
								<Modal.Header closeButton className="m-0">
								</Modal.Header>
								<div className="login-modal">
									<Modal.Body>
										<div className="animation_congratulations-img">
											<Image src="/images/animation/animation_congratulations.gif" className="img-fluid" alt="user" />
										</div>
										<div className="cong">
											<h3>{t('Congratulations !')}</h3>
											<p> {t('Your ad is live now...')} </p>
										</div>
									</Modal.Body>
								</div>
							</Modal>
						</Col>
					</Row>
				</Container>
			</div>
			<Footer />
		</>
	)
}
export default MrakListedView;