import i18next from "i18next";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import '../i18n' ;
import Loader from "../components/Loader/Loader";

export const langContext = createContext({
    lang:"en",
    changeLang:()=>{},
});

export function LangContextProvider({children}){
    let defaultLang = getCookie("defaultLang");
    if (defaultLang === '') {
        defaultLang = 'en';
    } 
    const[lang,setLang] = useState(defaultLang);
    const changeLang = (lang) => {
        i18next.changeLanguage(lang);
        setCookie("defaultLang", lang, 30);
        setLang(lang);
        window.location.reload()
    }
    const { ready } = useTranslation();
    
    
       
        if(ready === true) {
            return(
                <langContext.Provider value={{lang,changeLang}}>
                    {children}
                </langContext.Provider>
            )
        }else{
            return(
              <Loader/>
            )
        }
        
        
    
}

export  function useLangContext(){
    const {lang,changeLang} =  useContext(langContext);
    return {
        lang,
        changeLang
    };
 }  
 
function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
  
function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}
