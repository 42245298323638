import { createRoot } from "react-dom/client";
import './assets/css/App.css';
import App from './App';
import '../node_modules/font-awesome/css/font-awesome.css';
import { BrowserRouter } from "react-router-dom";
import AuthContext from "./Contexts/AuthContext";
import { HelmetProvider } from "react-helmet-async";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { LangContextProvider } from "./Contexts/langContext";

const container = document.getElementById('app');
const root = createRoot(container);
const google_app_id = process.env.REACT_APP_GOOGLE_APP_ID

// custom-patch to support google translate -- 
// if (typeof Node === 'function' && Node.prototype) {
//   const originalRemoveChild = Node.prototype.removeChild;
//   Node.prototype.removeChild = function (child) {
//     if (child.parentNode !== this) {
//       if (console) {
//         console.error('Cannot remove a child from a different parent', child, this);
//       }
//       return child;
//     }
//     return originalRemoveChild.apply(this, arguments);
//   }

//   const originalInsertBefore = Node.prototype.insertBefore;
//   Node.prototype.insertBefore = function (newNode, referenceNode) {
//     if (referenceNode && referenceNode.parentNode !== this) {
//       if (console) {
//         console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
//       }
//       return newNode;
//     }
//     return originalInsertBefore.apply(this, arguments);
//   }
// }
root.render(
  <GoogleOAuthProvider clientId={google_app_id}>
    <BrowserRouter>
      <AuthContext>
        <LangContextProvider>
          <HelmetProvider >
            <App />
          </HelmetProvider>
        </LangContextProvider>
      </AuthContext>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();


